import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-8" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = {
  key: 1,
  class: "error-form-field"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_10 = {
  key: 0,
  class: "error-form-field"
}
const _hoisted_11 = {
  key: 0,
  class: "w-2/5"
}
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "mb-3 assessment-editor" }
const _hoisted_14 = { class: "edit-assessment-buttons" }

import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { AssetPropCreateMutationInput, PropWithRecommendationInterface } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ASSET_PROPS_FORM_VALUES } from '@/grapql/assetPropsFormQueries';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';




export default /*@__PURE__*/_defineComponent({
  __name: 'AssetPropertyAddForm',
  emits: ['cancelAddition'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const store = useStore();
const onEditorReady = (event: Event) => {return event};
const erroFromMutation = computed(() => store.state.assetDetailModule.errorsDuringMutation.assetPropCreate)
const assetId = computed(() => store.getters['assetDetailModule/getAssetId']);

const { result } = useQuery(ASSET_PROPS_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const formValueOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes', 'valueTypes', 'props']));

const propsWithRecomendation = computed<PropWithRecommendationInterface[]>(() => inizilizedData.value && inizilizedData.value.props ? inizilizedData.value?.props.edges.map(edge => edge.node) : []);

const formSubmited = ref(false);
const valueError = computed(() => formSubmited.value && !value1.value ?  "Please assign a value to the property" : null);
const propertyError = computed(() => formSubmited.value && !prop.value ? "Pleasee select a property" : null);

const prop = ref<number>(null);
const valueType = ref<DropdownSearchableOptionInterface>(null);
const value1 = ref<string>(null);
const value2 = ref<string>(null);
const valueUnit = ref<number>(null);
const extraInformation = ref<string>(null);

watch(() => prop.value, () => {
	if(prop.value) {
		const propSelected = propsWithRecomendation.value.find(property => property.id == prop.value);
      if (propSelected && propSelected.baseValueType) {
         valueType.value =  formValueOptions.value['valueTypesOptions'].find(valueType => valueType.id == propSelected.baseValueType.id);
      }
      if (propSelected && propSelected.baseUnitType) { valueUnit.value = propSelected.baseUnitType.id;}
   }
})

const createMutationInput = () => {
   const createPropInput = ref<AssetPropCreateMutationInput>({
      asset: assetId.value, 
      prop: Number(prop.value),
      value1: value1.value,
      value2: value2.value,
      valueType: valueType.value ? valueType.value.id : null,
      valueUnit: valueUnit.value,
      extraInformation: extraInformation.value,
   });
   
   return createPropInput;
}

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!propertyError.value && !valueError.value) {
     const mutationInput = createMutationInput();
     await store.dispatch('assetDetailModule/assetPropCreate', mutationInput.value)
      .then(() => { 
         if (!erroFromMutation.value) {
            emit('cancelAddition')
         }
      })
   }
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[9] || (_cache[9] = _createElementVNode("label", null, "Property *", -1)),
      (formValueOptions.value['propsOptions'])
        ? (_openBlock(), _createBlock(DropdownSearchableSingle, {
            key: 0,
            "options-list": formValueOptions.value['propsOptions'],
            modelValue: prop.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((prop).value = $event))
          }, null, 8, ["options-list", "modelValue"]))
        : _createCommentVNode("", true),
      (propertyError.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString(propertyError.value), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("label", null, "Value Type", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((valueType).value = $event)),
      class: "w-full mb-3"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValueOptions.value['valueTypesOptions'], (valueType) => {
        return (_openBlock(), _createElementBlock("option", {
          key: valueType.id,
          value: valueType
        }, _toDisplayString(valueType.description), 9, _hoisted_4))
      }), 128))
    ], 512), [
      [_vModelSelect, valueType.value]
    ]),
    (valueType.value && valueType.value.name == 'Boolean')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[10] || (_cache[10] = _createElementVNode("label", null, "Value", -1)),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              class: "shrink-0",
              type: "checkbox",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((value1).value = $event)),
              "true-value": "true",
              "false-value": "false"
            }, null, 512), [
              [_vModelCheckbox, value1.value]
            ]),
            (value1.value && value1.value == 'true')
              ? (_openBlock(), _createElementBlock("label", _hoisted_7, "Yes"))
              : (_openBlock(), _createElementBlock("label", _hoisted_8, "No"))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: _normalizeClass([valueType.value && valueType.value.name == 'Between' ? 'w-2/5' : 'w-full', "mb-3"])
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "Value", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((value1).value = $event)),
              class: "w-full"
            }, null, 512), [
              [_vModelText, value1.value]
            ]),
            (valueError.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(valueError.value), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          (valueType.value && valueType.value.name == 'Between')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[12] || (_cache[12] = _createElementVNode("label", null, "Value 2", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((value2).value = $event)),
                  class: "w-full mb-3"
                }, null, 512), [
                  [_vModelText, value2.value]
                ])
              ]))
            : _createCommentVNode("", true)
        ])),
    _cache[18] || (_cache[18] = _createElementVNode("label", null, "Unit", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((valueUnit).value = $event)),
      class: "w-full mb-3"
    }, [
      _cache[13] || (_cache[13] = _createElementVNode("option", { value: null }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValueOptions.value['unitTypesOptions'], (unitOption) => {
        return (_openBlock(), _createElementBlock("option", {
          key: unitOption.id,
          value: unitOption.id
        }, _toDisplayString(unitOption.abbreviation) + " - " + _toDisplayString(unitOption.name), 9, _hoisted_12))
      }), 128))
    ], 512), [
      [_vModelSelect, valueUnit.value]
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[14] || (_cache[14] = _createElementVNode("label", null, "Extra Information", -1)),
      _createVNode(_unref(QuillEditor), {
        content: extraInformation.value,
        "onUpdate:content": _cache[6] || (_cache[6] = ($event: any) => ((extraInformation).value = $event)),
        "content-type": "html",
        onReady: _cache[7] || (_cache[7] = ($event: any) => (onEditorReady($event)))
      }, null, 8, ["content"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", {
        onClick: handleSaveChanges,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[15] || (_cache[15] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", {
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('cancelAddition')))
      }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[16] || (_cache[16] = _createElementVNode("p", null, "cancel", -1))
      ])
    ])
  ]))
}
}

})