import gql from "graphql-tag";

export const ASSETS_QUERY = gql`
  
  query getAssets(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $orderBy: [String]
    $filter: AssetNodeFilterInputType
  ) {
    assets(
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
      before: $before
      last: $last
    ) @connection(key: "assets") {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          nickname
          title
          abstract
          assetType
          sources {
            edges {
              node {
                name
              }
            }
          }
          publicationDate
          applicationTypes {
            edges {
              node {
                name
              }
            }
          }
          url
          tags
          description
          notes
          materials {
            edges {
              node {
                name
              }
            }
          }
          processingTechniques {
            edges {
              node {
                id
                name
              }
            }
          }
          standards {
            edges {
              node {
                id
                code
                title
              }
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;


export const OTHER_ASSETS = gql`  
  query getOtherTechnologies( $first: Int, $after: String, $before: String, $last: Int, $orderBy: [String], $filter:  OtherTechnologyNodeFilterInputType) {
    otherTechnologies(first: $first, after: $after, orderBy: $orderBy, filter: $filter, before: $before, last: $last, ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          nickname
          title
          abstract
          assetType
          sources {
            edges {
              node {
                name
              }
            }
          }
          assetFiles {edges {node {id file {name url} deleted}}} 
          owners {
          edges {
            node {
              id
              nickname
            }
          }
        }
          publicationDate
          applicationTypes {
            edges {
              node {
                name
              }
            }
          }
          url
          tags
          description
          notes
          materials {
            edges {
              node {
                name
              }
            }
          }
          processingTechniques {
            edges {
              node {
                id
                name
              }
            }
          }
          standards {
            edges {
              node {
                id
                code
                title
              }
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;


export const PATENT_ASSETS = gql`  
  query getPatents( $first: Int, $after: String, $before: String, $last: Int, $orderBy: [String], $filter: PatentNodeFilterInputType) {
    patents(first: $first, after: $after, orderBy: $orderBy, filter: $filter, before: $before, last: $last, ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          nickname
          title
          abstract
          assetType
          sources {
            edges {
              node {
                name
              }
            }
          }
          publicationDate
          applicationTypes {
            edges {
              node {
                name
              }
            }
          }
          url
          tags
          description
          notes
          materials {
            edges {
              node {
                name
              }
            }
          }
          processingTechniques {
            edges {
              node {
                id
                name
              }
            }
          }
          standards {
            edges {
              node {
                id
                code
                title
              }
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;

export const RESEARCH_PAPERS_ASSETS = gql`  
  query getResearchPapers( $first: Int, $after: String, $before: String, $last: Int, $orderBy: [String], $filter: ResearchPaperNodeFilterInputType) {
    researchPapers(first: $first, after: $after, orderBy: $orderBy, filter: $filter, before: $before, last: $last, ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          nickname
          title
          abstract
          assetType
          sources {
            edges {
              node {
                name
              }
            }
          }
          publicationDate
          applicationTypes {
            edges {
              node {
                name
              }
            }
          }
          url
          tags
          description
          notes
          materials {
            edges {
              node {
                name
              }
            }
          }
          processingTechniques {
            edges {
              node {
                id
                name
              }
            }
          }
          standards {
            edges {
              node {
                id
                code
                title
              }
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;

export const RESEARCH_PROJECTS_ASSETS = gql`  
  query getResearchProjects( $first: Int, $after: String, $before: String, $last: Int, $orderBy: [String], $filter: ResearchProjectNodeFilterInputType) {
    researchProjects(first: $first, after: $after, orderBy: $orderBy, filter: $filter, before: $before, last: $last, ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          nickname
          title
          abstract
          assetType
          sources {
            edges {
              node {
                name
              }
            }
          }
          publicationDate
          applicationTypes {
            edges {
              node {
                name
              }
            }
          }
          url
          tags
          description
          notes
          materials {
            edges {
              node {
                name
              }
            }
          }
          processingTechniques {
            edges {
              node {
                id
                name
              }
            }
          }
          standards {
            edges {
              node {
                id
                code
                title
              }
            }
          }
        }
      }
      totalCount
      edgeCount
    }
  }
`;


export const TOTAL_ASSETS_BY_TYPE = gql`
  query getAllTotals {
    researchPapers {
      totalCount
    }
    researchProjects {
      totalCount
    }
    patents {
      totalCount
    }
    otherTechnologies {
      totalCount
    }
    assets {
      totalCount
    }
  }
`;

export const TOTAL_ASSETS_QUERY = gql`
  query getTotalAssets{
    assets {
      totalCount
    }
  }
`

export const BENCHLINK_ASSET_QUERY = gql`
  query findCommercialAsset($filter: OtherTechnologyNodeFilterInputType) {
    otherTechnologies(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`