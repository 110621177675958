import gql from "graphql-tag";

export const ASSETS_COMPARISON_CRITERIA_OPTIONS = gql`
query getComparisonCriteriaOptions {
  props {
      edges {
        node {
          id
          name
          propCategory {
            id
            name
            propClass {
              id
              name
            }
          }
        }
      }
    }
}`

export const ALL_ASSETS = gql`
query getAssets ($filter:  OtherTechnologyNodeFilterInputType) {
  otherTechnologies(filter: $filter,
    orderBy: "nickname"
  ) {
    edges {
      node {
        id
        nickname
        title
      }
    }
  }
}`

export const LAYER_TYPES = gql`
query layerTypes {
  layerTypes{
    edges {
      node {
        id
        name
      }
    }
  }
}`

