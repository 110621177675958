import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "my-6 w-full" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_9 = {
  key: 0,
  class: "error-form-field"
}
const _hoisted_10 = {
  key: 0,
  class: "w-2/5"
}
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "mb-3 assessment-editor" }
const _hoisted_13 = { class: "edit-assessment-buttons mb-6" }

import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { AssetPropInterface, AssetPropUpdateMutationInput } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { ref, computed} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ASSET_PROPS_FORM_VALUES } from '@/grapql/assetPropsFormQueries';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetPropertyEditForm',
  props: {
    assetProp: {}
  },
  emits: ['cancelEdition'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const store = useStore();
const onEditorReady = (event: Event) => {return event};
const erroFromMutation = computed(() => store.state.assetDetailModule.errorsDuringMutation.assetPropUpdate)

const { result } = useQuery(ASSET_PROPS_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const formValueOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes', 'valueTypes']));

const formSubmited = ref(false);
const valueError = computed(() => formSubmited.value && !value1.value ?  "Please assign a value to the property" : null);

const valueType = props.assetProp.valueType ? ref(props.assetProp.valueType) : ref<DropdownSearchableOptionInterface>(null);
const value1 = props.assetProp.value1 ? ref(props.assetProp.value1) : ref<string>(null);
const value2 = props.assetProp.value2 ? ref(props.assetProp.value2) : ref<string>(null);
const valueUnit = props.assetProp.valueUnit ? ref(props.assetProp.valueUnit.id) : ref<number>(null);
const extraInformation = props.assetProp.extraInformation ? ref(props.assetProp.extraInformation) : ref<string>(null);



const createMutationInput = () => {
   const propUpdateInput = ref<AssetPropUpdateMutationInput>({
      id: props.assetProp.id,
      prop: props.assetProp.prop.id,
      value1: value1.value,
      value2: value2.value,
      valueType: valueType.value ? valueType.value.id : null,
      valueUnit: valueUnit.value,
      extraInformation: extraInformation.value,
   });
   return propUpdateInput;
}

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!valueError.value) {
     const mutationInput = createMutationInput();
     await store.dispatch('assetDetailModule/assetPropUpdate', mutationInput.value)
      .then(() => { 
         if (!erroFromMutation.value) {
            emit('cancelEdition');
         }
      })
   }
}

  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[15] || (_cache[15] = _createElementVNode("label", null, "Property", -1)),
    _createElementVNode("input", {
      type: "text",
      class: "w-full mb-3",
      disabled: "",
      value: _ctx.assetProp.prop.name
    }, null, 8, _hoisted_2),
    _cache[16] || (_cache[16] = _createElementVNode("label", null, "Value Type", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(valueType) ? (valueType).value = $event : null)),
      class: "w-full mb-3"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValueOptions.value['valueTypesOptions'], (valueType) => {
        return (_openBlock(), _createElementBlock("option", {
          key: valueType.id,
          value: valueType
        }, _toDisplayString(valueType.description), 9, _hoisted_3))
      }), 128))
    ], 512), [
      [_vModelSelect, _unref(valueType)]
    ]),
    (_unref(valueType) && _unref(valueType).name == 'Boolean')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("label", null, "Value", -1)),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              class: "shrink-0",
              type: "checkbox",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(value1) ? (value1).value = $event : null)),
              "true-value": "true",
              "false-value": "false"
            }, null, 512), [
              [_vModelCheckbox, _unref(value1)]
            ]),
            (_unref(value1) && _unref(value1) == 'true')
              ? (_openBlock(), _createElementBlock("label", _hoisted_6, "Yes"))
              : (_openBlock(), _createElementBlock("label", _hoisted_7, "No"))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", {
            class: _normalizeClass([_unref(valueType) && _unref(valueType).name == 'Between' ? 'w-2/5' : 'w-full', "mb-3"])
          }, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, "Value", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(value1) ? (value1).value = $event : null)),
              class: "w-full"
            }, null, 512), [
              [_vModelText, _unref(value1)]
            ]),
            (valueError.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                  _createElementVNode("label", null, _toDisplayString(valueError.value), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          (_unref(valueType) && _unref(valueType).name == 'Between')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Value 2", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(value2) ? (value2).value = $event : null)),
                  class: "w-full mb-3"
                }, null, 512), [
                  [_vModelText, _unref(value2)]
                ])
              ]))
            : _createCommentVNode("", true)
        ])),
    _cache[17] || (_cache[17] = _createElementVNode("label", null, "Unit", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(valueUnit) ? (valueUnit).value = $event : null)),
      class: "w-full mb-3"
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("option", { value: null }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValueOptions.value['unitTypesOptions'], (unitOption) => {
        return (_openBlock(), _createElementBlock("option", {
          key: unitOption.id,
          value: unitOption.id
        }, _toDisplayString(unitOption.abbreviation) + " - " + _toDisplayString(unitOption.name), 9, _hoisted_11))
      }), 128))
    ], 512), [
      [_vModelSelect, _unref(valueUnit)]
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[12] || (_cache[12] = _createElementVNode("label", null, "Extra Information", -1)),
      _createVNode(_unref(QuillEditor), {
        content: _unref(extraInformation),
        "onUpdate:content": _cache[5] || (_cache[5] = ($event: any) => (_isRef(extraInformation) ? (extraInformation).value = $event : null)),
        "content-type": "html",
        onReady: _cache[6] || (_cache[6] = ($event: any) => (onEditorReady($event)))
      }, null, 8, ["content"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        onClick: handleSaveChanges,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[13] || (_cache[13] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", {
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('cancelEdition')))
      }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[14] || (_cache[14] = _createElementVNode("p", null, "cancel", -1))
      ])
    ])
  ]))
}
}

})