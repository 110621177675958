import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-span-5"
}
const _hoisted_2 = { class: "flex justify-between items-center flex-wrap mb-6" }
const _hoisted_3 = { class: "flex justify-between items-strech" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = {
  key: 0,
  class: "flex trash"
}
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "font-medium capitalize" }
const _hoisted_8 = { class: "capitalize italic mb-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "flex text-neutral-950 text-sm font-normal" }
const _hoisted_11 = { class: "mr-2 uppercase font-medium" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "mx-2" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 2,
  class: "ml-4 font-light text-sm"
}
const _hoisted_17 = { class: "font-normal" }
const _hoisted_18 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_19 = {
  key: 1,
  class: "flex items-center"
}

import { PROGRAM_VERSION_TARGETS_FORM } from '@/grapql/programVersionTargetsQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { ProgramVersionTargetsInterface, TargetToDisplayInterface } from '@/models/programVersionsInterface';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { XCircleIcon, PencilSquareIcon, ArrowUturnLeftIcon, TrashIcon } from '@heroicons/vue/24/outline';
import ProgramVersionTargetFormFields from './ProgramVersionTargetFormFields.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionTargetsForm',
  props: {
    onEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props
const store = useStore();

const programVersionTargets = computed<ProgramVersionTargetsInterface[]>(() => props.onEdit ? store.state.programVersionDetailModule['programVersionTargetsForEdition'] : store.state.programsCreationModule['programVersion']['programVersionTargets']);
const targetsToDelete = computed<number[]>(() => store.state.programVersionDetailModule.targetsToDelete );


const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

// Inizialize the form
const { result } = useQuery(PROGRAM_VERSION_TARGETS_FORM);
const inizilizedData = computed(() => result?.value ?? null);

const properties = computed(() => inizilizedData.value && inizilizedData.value.props ? inizilizedData.value?.props.edges.map(edge => edge.node) : []);
const targetCategoryTypes = computed(() => inizilizedData.value && inizilizedData.value.targetCategoryTypes ? inizilizedData.value.targetCategoryTypes.edges.map(edge => edge.node) : []);
const units = computed(() => inizilizedData.value && inizilizedData.value.unitTypes ? inizilizedData.value.unitTypes.edges.map(edge => edge.node) : []);
const valueTypes = computed(() => inizilizedData.value && inizilizedData.value.valueTypes ? inizilizedData.value.valueTypes.edges.map(edge => edge.node) : []);

const propertiesDropdownValues = computed<DropdownSearchableOptionInterface[]>(() => properties.value ? properties.value.map(prop => Object.assign({}, { id: prop.id, name: prop.name })) : []);

const targetsToDisplay = computed<TargetToDisplayInterface[]>(() => {
    const targets = [];
    if (programVersionTargets.value.length && inizilizedData.value) {
        programVersionTargets.value.map(target => {
            const displayTarget = parseTarget(target)
            targets.push(displayTarget)
        })
    }
    return targets
});

const targetIndexToEdit = ref(null);
const setTargetToEdit = (id: number) => {
    const targetToEdit = programVersionTargets.value.find(target => target.id == id);
    targetIndexToEdit.value = programVersionTargets.value.indexOf(targetToEdit);
}
const targetToEdit = computed(() => targetIndexToEdit.value != null ? programVersionTargets.value[targetIndexToEdit.value] : null)


const handleNewTarget = (targetToAdd) => {
    delete targetToAdd.id
    delete targetToAdd.currentValue
    programVersionTargets.value.push(targetToAdd);
}

const handleEditedTarget = (editedTarget) => {
    programVersionTargets.value[targetIndexToEdit.value] = editedTarget;
    targetIndexToEdit.value = null;
}

const removeTarget = (index: number) => {
    programVersionTargets.value.splice(index, 1);
}

const addTargetToDelete = (id: number) => {
    targetsToDelete.value.push(id);
    targetIndexToEdit.value = null;
}

const removeTargetToDelete = (id: number) => targetsToDelete.value.splice(targetsToDelete.value.indexOf(id), 1)

const parseTarget = (target: ProgramVersionTargetsInterface) => {
    const valueType = valueTypes.value.find(tv => tv.id == target.targetValueType);
    const displayTarget: TargetToDisplayInterface = {
        id: target.id ? target.id : null,
        targetCategoryType: targetCategoryTypes.value.find(tct => tct.id == target.targetCategoryType).name,
        targetPropName: target.targetProp ? properties.value.find(p => p.id == target.targetProp).name : null,
        targetPropClass: target.targetProp ? properties.value.find(p => p.id == target.targetProp).propCategory.propClass.name : null,
        targetPropCategory: target.targetProp ? properties.value.find(p => p.id == target.targetProp).propCategory.name : null,
        targetValue1: target.targetValue1 == 'true' ? 'Yes' : target.targetValue1 == 'false' ? 'No' : target.targetValue1,
        targetValueTypeName: valueType.name == 'Boolean' ? null : valueType.name == 'Between' ? valueType.name : valueType.symbol,
        targetValueUnit: target.targetValueUnit ? units.value.find(unit => unit.id == target.targetValueUnit).abbreviation : null,
        targetValue2: target.targetValue2 ? target.targetValue2 : null,
        currentValue: target.currentValue ? target.currentValue : null
    }
    return displayTarget;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (targetsToDisplay.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(targetsToDisplay.value, (target, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["target-card shadow-md", {'to-delete': _ctx.onEdit && targetsToDelete.value.includes(target.id)}])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.onEdit && targetsToDelete.value.includes(target.id))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_unref(TrashIcon), { class: "icon icon-m" })
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(target.targetCategoryType) + " property", 1),
                      _createElementVNode("p", _hoisted_8, [
                        _createTextVNode(_toDisplayString(target.targetPropCategory) + " ", 1),
                        (target.targetPropClass != 'Unknown')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, " / " + _toDisplayString(target.targetPropClass), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("p", _hoisted_11, _toDisplayString(target.targetPropName) + " : ", 1),
                        (target.targetValueTypeName)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(target.targetValueTypeName), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", _hoisted_13, [
                          _createTextVNode(_toDisplayString(target.targetValue1) + " ", 1),
                          (target.targetValue2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, " and " + _toDisplayString(target.targetValue2), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (target.targetValueUnit)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(target.targetValueUnit), 1))
                          : _createCommentVNode("", true),
                        (target.currentValue && isUserAllowToSeeExtraInfo.value)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
                              _cache[0] || (_cache[0] = _createTextVNode(" ( Current Value: ")),
                              _createElementVNode("span", _hoisted_17, _toDisplayString(target.currentValue), 1),
                              _cache[1] || (_cache[1] = _createTextVNode(" )"))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  (_ctx.onEdit && targetsToDelete.value.includes(target.id))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createVNode(_unref(ArrowUturnLeftIcon), {
                          class: "icon icon-small ml-3",
                          onClick: ($event: any) => (removeTargetToDelete(target.id))
                        }, null, 8, ["onClick"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        (_ctx.onEdit && target.id)
                          ? (_openBlock(), _createBlock(_unref(PencilSquareIcon), {
                              key: 0,
                              class: "icon icon-small mr-4",
                              onClick: ($event: any) => (setTargetToEdit(target.id))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.onEdit && target.id)
                          ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
                              key: 1,
                              class: "icon icon-small",
                              onClick: ($event: any) => (addTargetToDelete(target.id))
                            }, null, 8, ["onClick"]))
                          : (_openBlock(), _createBlock(_unref(XCircleIcon), {
                              key: 2,
                              class: "icon icon-small",
                              onClick: ($event: any) => (removeTarget(index))
                            }, null, 8, ["onClick"]))
                      ]))
                ])
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.onEdit && targetToEdit.value)
      ? (_openBlock(), _createBlock(ProgramVersionTargetFormFields, {
          key: targetToEdit.value.id,
          "on-edit": true,
          "target-to-edit": targetToEdit.value,
          properties: propertiesDropdownValues.value,
          units: units.value,
          valueTypes: valueTypes.value,
          targetCategoryTypes: targetCategoryTypes.value,
          onEditedTarget: handleEditedTarget
        }, null, 8, ["target-to-edit", "properties", "units", "valueTypes", "targetCategoryTypes"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(ProgramVersionTargetFormFields, {
      key: programVersionTargets.value.length,
      properties: propertiesDropdownValues.value,
      propsWithRecommendation: properties.value,
      units: units.value,
      valueTypes: valueTypes.value,
      targetCategoryTypes: targetCategoryTypes.value,
      onNewTarget: handleNewTarget
    }, null, 8, ["properties", "propsWithRecommendation", "units", "valueTypes", "targetCategoryTypes"]))
  ], 64))
}
}

})