import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/icons/clip-load.gif'


const _hoisted_1 = { class: "request-container" }
const _hoisted_2 = {
  key: 0,
  class: "loader"
}
const _hoisted_3 = { class: "btn-modal" }

import ModalSlots from "@/components/ModalSlots.vue";
import { ref, onUpdated } from 'vue';
import { TransitionRoot } from '@headlessui/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalRequestForm',
  props: { isOpen: Boolean },
  emits: ['closeModal'],
  setup(__props) {




const loading = ref(true);

onUpdated(() => {
    if (loading.value) {
        setTimeout(() => {
          loading.value = false
        }, 3000)
    } else {  setTimeout(() => {
      loading.value = true
        }, 800)}
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    show: __props.isOpen,
    onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
    dialogClass: "prototype-designer-modal"
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("p", null, null, -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(TransitionRoot), null, {
          default: _withCtx(() => [
            (loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    loading: "lazy"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true),
            _cache[4] || (_cache[4] = _createElementVNode("iframe", {
              class: "airtable-embed",
              src: "https://airtable.com/embed/appkETMHHt4xkfcSH/shrSBvmlj1LtqJEzM",
              frameborder: "0",
              onmousewheel: "",
              width: "100%",
              height: "533",
              style: {"background":"transparent","border":"1px solid #ccc"}
            }, null, -1))
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "button",
          class: "button cancel x-cancel",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
        }, " Close ")
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})