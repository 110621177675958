import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group hover:bg-gray-200 cursor-pointer rounded text-sm flex justify-between items-center" }
const _hoisted_2 = { style: {"font-size":"0.6rem"} }
const _hoisted_3 = { class: "chat-actions hidden group-hover:flex p-2 rounded" }

import { PropType } from 'vue';
import {PencilIcon, TrashIcon} from '@heroicons/vue/24/outline';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import {ChatKeyUrlMap } from '@/models/aiModels';
import { IChat } from '@/models/aiInterfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatHistoryItem',
  props: {
  chat: {
    type: Object as PropType<IChat>,
    required: true
  }
},
  emits: ["chatDelete","chatRename"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const router = useRouter();

const handleChatClick = (chatId, chatKey) => {
  router.push({name: 'AI', params: {id: chatId, chatKey: ChatKeyUrlMap[chatKey]}});
}

const handleChatDelete = (chat) => {
  emit("chatDelete", chat);
}
const handleChatRename = (chat) => {
  emit("chatRename", chat);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "whitespace-nowrap overflow-hidden block p-2 rounded w-full text-xs",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (handleChatClick(__props.chat.id,__props.chat.chatKey)))
    }, [
      _createElementVNode("span", null, [
        _createElementVNode("small", _hoisted_2, _toDisplayString(__props.chat.chatKey), 1)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(__props.chat.name), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(Menu), {
        as: "section",
        class: "relative"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(MenuButton), { class: "font-bold" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(". . .")
            ])),
            _: 1
          }),
          _createVNode(_unref(MenuItems), { class: "absolute right-0 bg-gray-100 border border-gray-300 shadow-sm rounded-md text-sm cursor-pointer pt-2 pb-1" }, {
            default: _withCtx(() => [
              _createVNode(_unref(MenuItem), { class: "hover:bg-gray-200 px-2 py-1 mb-1 flex items-center text-xs" }, {
                default: _withCtx(({active}) => [
                  _createElementVNode("div", {
                    class: _normalizeClass({"bg":active}),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (handleChatRename(__props.chat)))
                  }, [
                    _createVNode(_unref(PencilIcon), { class: "icon icon-x-small inline-block mr-3" }),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "inline-block" }, "Rename", -1))
                  ], 2)
                ]),
                _: 1
              }),
              _createVNode(_unref(MenuItem), { class: "hover:bg-gray-200 px-2 py-1 mb-1 flex items-center text-xs text-red-500" }, {
                default: _withCtx(({active}) => [
                  _createElementVNode("div", {
                    class: _normalizeClass({"bg":active}),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (handleChatDelete(__props.chat)))
                  }, [
                    _createVNode(_unref(TrashIcon), { class: "icon icon-x-small inline-block mr-3 red" }),
                    _cache[6] || (_cache[6] = _createElementVNode("span", null, "Delete", -1))
                  ], 2)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})