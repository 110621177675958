import gql from "graphql-tag";

export const ASSETS_FILTER_OPTIONS = gql`
query getAssetsFilterOptions {
  sources(orderBy: "name") {
    edges {
      node {
        id
        name
      }
    }
  }
  tags(orderBy: "name") {
    edges {
      node {
        id
        name
      }
    }
  }
  applicationTypes(orderBy: "name") {
    edges {
      node {
        id
        name
      }
    }
  }
  materials(orderBy: "name") {
    edges {
      node {
        id
        name
      }
    }
  }
  standards(orderBy: "description") {
    edges {
      node {
        id
        description
      }
    }
  }
  processingTechniques(orderBy: "name") {
    edges {
      node {
        id
        name
      }
    }
  }
}`

export const ASSETS_EVALUATION_STATUS_OPTIONS = gql`
  query getAssetEvaluationStatuses {
    assetEvaluationStatuses {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const PROGRAMS_OPTIONS = gql`
  query getPrograms {
    programs {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`