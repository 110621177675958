import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = {
  key: 0,
  class: "add-asset-form"
}
const _hoisted_3 = { class: "grid grid-cols-3 gap-y-2.5 gap-x-4" }
const _hoisted_4 = { class: "col-span-3 grid" }
const _hoisted_5 = { class: "col-span-3" }
const _hoisted_6 = { class: "col-span-2" }
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_9 = {
  key: 0,
  class: "flex justify-between items-center"
}
const _hoisted_10 = ["id", "onClick"]
const _hoisted_11 = ["value", "onInput"]
const _hoisted_12 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_13 = { class: "mt-3" }
const _hoisted_14 = { class: "col-span-3 grid" }
const _hoisted_15 = { class: "col-span-3 mt-2" }
const _hoisted_16 = { class: "form-error-toast" }

import { ASSET_ADD_FORM_VALUES } from '@/grapql/assetAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { useMultipleSelect, useMultipleSelectForSources } from '@/composables/useMultipleSelect';
import Datepicker from 'vue3-datepicker';
import { useFormFieldsCreationNewAsset } from '@/composables/useFormFieldsCreationNewAsset';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import FormErrorToast from '@/components/FormErrorToast.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import DropdownSearchableWithFilter from './DropdownSearchableWithFilter.vue';
import FilesUploadFormField from './FilesUploadFormField.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import PartyAddNew from '@/components/PartyAddNew.vue';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';

const tempLinks = '';

export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsAddNewResearchProject',
  props: ['errorsFields', 'formIsValid', 'formSubmited'],
  setup(__props) {


const tempNewTag = ref('');
const showTagInput = ref(false);
const showSourceIDInput = ref([]);

// Inizialize the form
const { result } = useQuery(ASSET_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null)
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_ASSET_FIELDS.RESEARCH_PROJECT.fieldsToQuery));


// Create Fields for the form
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
const formFieldOptions = {
    fields: NEW_ASSET_FIELDS.RESEARCH_PROJECT.formFileds,
    base: NEW_ASSET_FIELDS.RESEARCH_PROJECT.graphqlValue,
    mutation: 'assetCreationModule/setSingleAssetValue'
};
const formFields = useFormFieldsCreationNewAsset(formFieldOptions);
const onEditorReady = (event: Event) => { return event };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formInizializationValues.value)
      ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "External Opportunity Info")
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass({ 'error-form-field': __props.errorsFields.includes('nickname') && __props.formSubmited })
            }, [
              _cache[19] || (_cache[19] = _createElementVNode("label", null, "Nickname *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['nickname'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['nickname'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-start-auto col-span-2", { 'error-form-field': __props.errorsFields.includes('title') && __props.formSubmited }])
            }, [
              _cache[20] || (_cache[20] = _createElementVNode("label", null, "Title *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['title'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['title'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-3 grid", { 'error-form-field': __props.errorsFields.includes('abstract') && __props.formSubmited }])
            }, [
              _cache[21] || (_cache[21] = _createElementVNode("label", null, "Abstract *", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['abstract'].value ? _unref(formFields)['abstract'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[2] || (_cache[2] = content => _unref(formFields)['abstract'].value = content),
                onReady: _cache[3] || (_cache[3] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ], 2),
            _createElementVNode("div", _hoisted_4, [
              _cache[22] || (_cache[22] = _createElementVNode("label", null, "Description", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['description'].value ? _unref(formFields)['description'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[4] || (_cache[4] = content => _unref(formFields)['description'].value = content),
                onReady: _cache[5] || (_cache[5] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[23] || (_cache[23] = _createElementVNode("label", null, "Url", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formFields)['url'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['url'].value]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[26] || (_cache[26] = _createElementVNode("label", null, [
                _createTextVNode("Sources "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[7] || (_cache[7] = ($event: any) => (_unref(formFields)['sources'].value = _unref(useMultipleSelectForSources)(Number(handleInputChange($event)), 'add', _unref(formFields)['sources'].value)))
              }, [
                _cache[24] || (_cache[24] = _createElementVNode("option", {
                  selected: "",
                  disabled: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['sourcesOptions'], (source) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: source.id,
                    key: source.id
                  }, _toDisplayString(source.name), 9, _hoisted_7))
                }), 128))
              ], 32),
              (_unref(formFields)['sources'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['sourcesOptions'], (sourceOption, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['sources'].value, (source, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (source.source == sourceOption.id)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createElementVNode("span", null, [
                                    _createTextVNode(_toDisplayString(sourceOption.name) + " ", 1),
                                    _createVNode(_unref(XMarkIcon), {
                                      class: "icon icon-x-small thick inline",
                                      onClick: ($event: any) => (_unref(formFields)['sources'].value = _unref(useMultipleSelectForSources)(source.source, 'remove', _unref(formFields)['sources'].value))
                                    }, null, 8, ["onClick"])
                                  ]),
                                  _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("div", {
                                      id: index.toString(),
                                      class: "label-with-icon",
                                      onClick: ($event: any) => (showSourceIDInput.value.push(index))
                                    }, _cache[25] || (_cache[25] = [
                                      _createElementVNode("span", { class: "icon-span" }, "+ Add ID", -1)
                                    ]), 8, _hoisted_10), [
                                      [_vShow, !showSourceIDInput.value.includes(index)]
                                    ]),
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      placeholder: "ID",
                                      value: source.externalSourceId,
                                      onInput: ($event: any) => (source.externalSourceId = handleInputChange($event))
                                    }, null, 40, _hoisted_11), [
                                      [_vShow, showSourceIDInput.value.includes(index)]
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[27] || (_cache[27] = _createElementVNode("label", null, [
                _createTextVNode("Links "),
                _createElementVNode("span", null, "(Press Enter to add a link)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((tempLinks) = $event)),
                type: "text",
                onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => {_unref(formFields)['links'].value.push(handleInputChange($event)); tempLinks = ''}, ["enter"]))
              }, null, 544), [
                [_vModelText, tempLinks]
              ]),
              (_unref(formFields)['links'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['links'].value, (link, index) => {
                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                        _createTextVNode(_toDisplayString(link) + " ", 1),
                        _createVNode(_unref(XMarkIcon), {
                          class: "icon icon-x-small thick inline",
                          onClick: ($event: any) => (_unref(formFields)['links'].value.splice(index, 1))
                        }, null, 8, ["onClick"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[36] || (_cache[36] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "External Opportunity Participants")
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-2", { 'error-form-field': __props.errorsFields.includes('owner') && __props.formSubmited }])
            }, [
              _createVNode(DropdownSearchableWithFilter, {
                modelValue: _unref(formFields)['owner'].value,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(formFields)['owner'].value) = $event)),
                "filter-criteria": 'partyType',
                "options-list": formInizializationValues.value['partiesOptions'],
                "select-label": 'Owner *',
                "filter-options": [{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }],
                "filter-label": 'Filter Owners by:',
                "two-columns": true,
                "is-multiple": false
              }, null, 8, ["modelValue", "options-list"])
            ], 2),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(PartyAddNew, { "party-type": "owner" })
            ]),
            _cache[37] || (_cache[37] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "External Opportunity Assessment")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[28] || (_cache[28] = _createElementVNode("label", null, [
                _createTextVNode("Materials "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['materialsOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['materialsOptions'],
                    modelValue: _unref(formFields)['materials'].value,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(formFields)['materials'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[29] || (_cache[29] = _createElementVNode("label", null, [
                _createTextVNode("Applications "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['applicationTypesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['applicationTypesOptions'],
                    modelValue: _unref(formFields)['applicationTypes'].value,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(formFields)['applicationTypes'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[30] || (_cache[30] = _createElementVNode("label", null, [
                _createTextVNode("Certifications & standards "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['standardsOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['standardsOptions'],
                    modelValue: _unref(formFields)['standards'].value,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(formFields)['standards'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[31] || (_cache[31] = _createElementVNode("label", null, [
                _createTextVNode("Processing Techniques "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['processingTechniquesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['processingTechniquesOptions'],
                    modelValue: _unref(formFields)['processingTechniques'].value,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(formFields)['processingTechniques'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[32] || (_cache[32] = _createElementVNode("label", null, [
                _createTextVNode("TRL "),
                _createElementVNode("span", null, "Number between 1 and 9")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "number",
                min: "1",
                max: "9",
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(formFields)['trl'].value) = $event))
              }, null, 512), [
                [
                  _vModelText,
                  _unref(formFields)['trl'].value,
                  void 0,
                  { number: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[33] || (_cache[33] = _createElementVNode("label", null, "Notes", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['notes'].value ? _unref(formFields)['notes'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[16] || (_cache[16] = content => _unref(formFields)['notes'].value = content),
                onReady: _cache[17] || (_cache[17] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(FilesUploadFormField, { "asset-type": "researchProject" })
            ]),
            _cache[38] || (_cache[38] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "External Opportunity Dates")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[34] || (_cache[34] = _createElementVNode("label", null, "Publication Date", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                "upper-limit": new Date(),
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['publicationDate'].value,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(formFields)['publicationDate'].value) = $event))
              }, null, 8, ["upper-limit", "modelValue"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(FormErrorToast, {
        "is-showing": __props.formSubmited && !__props.formIsValid,
        title: "The following fields are mandatory:",
        subtitle: __props.errorsFields.join(', ')
      }, null, 8, ["is-showing", "subtitle"])
    ])
  ]))
}
}

})