<template>
    <div class="sidebar" :class="{ collapsed: collapsed }">
        <img v-if="collapsed" src="@/assets/images/one-five-icon-white.png" alt="one-five-logo"
            @click="$router.push({ name: 'TechnologyAssetsRepo' })">
        <img v-else src="@/assets/images/one-five-blue-trademark.png" alt="one-five-logo" class="logo-trademark"
            @click="$router.push({ name: 'TechnologyAssetsRepo' })">
        <div class="sidebar-content">
            <div class="sidebar-links">
                <!-- <router-link to="/dashboard" class="flex items-center tooltip-wrapper">
                    <PresentationChartBarIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Dashboard</span>
                    <p class="ml-4" v-if="!collapsed">Dashboard</p>
                </router-link> -->
                <router-link to="/playground" class="flex items-center tooltip-wrapper">
                    <PlayPauseIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Playground</span>
                    <p class="ml-4" v-if="!collapsed">Playground</p>
                </router-link>
                <router-link to="/product-briefs" class="flex items-center tooltip-wrapper">
                    <ClipboardDocumentCheckIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Product Briefs</span>
                    <p class="ml-4" v-if="!collapsed">Product Briefs</p>
                </router-link>
                <!-- <router-link v-if="isUserAllowToSeeExtraInfo" to="/programmes" class="flex items-center tooltip-wrapper">
                    <Cog6ToothIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Programmes</span>
                    <p class="ml-4" v-if="!collapsed">Programmes</p>
                </router-link> -->
                <router-link to="/programmes-wip" class="flex items-center tooltip-wrapper">
                    <WrenchScrewdriverIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Programmes</span>
                    <p class="ml-4" v-if="!collapsed">Programmes</p>
                </router-link>
                <!-- <router-link to="/research-projects" class="flex items-center tooltip-wrapper">
                    <AcademicCapIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Research Projects</span>
                    <p class="ml-4" v-if="!collapsed">Research Projects</p>
                </router-link> -->
                <router-link to="/technology-assets-repo" class="flex items-center tooltip-wrapper">
                    <TableCellsIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">Technology Assets Repo</span>
                    <p class="ml-4" v-if="!collapsed">Technology Assets Repo</p>
                </router-link>
                <router-link to="/polymers" class="flex items-center tooltip-wrapper">
                    <img v-if="collapsed" src="@/assets/icons/polymer.svg" class="icon icon-small polymer" />
                    <img v-if="!collapsed" src="@/assets/icons/polymer-gray.svg" class="icon icon-small polymer" />
                    <span v-if="collapsed" class="tooltip-content">Polymers Repository</span>
                    <p class="ml-4" v-if="!collapsed">Polymers Repository</p>
                </router-link>
                <!-- TEA menu item -->
                <router-link to="/tea-dashboard" class="flex items-center tooltip-wrapper">
                    <PresentationChartBarIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">TEA</span>
                    <p class="ml-4" v-if="!collapsed">TEA</p>
                </router-link>
                <router-link to="/ai" class="flex items-center tooltip-wrapper">
                    <CpuChipIcon class="icon icon-small" />
                    <span v-if="collapsed" class="tooltip-content">AI</span>
                    <p class="ml-4" v-if="!collapsed">Artificial Intelligence</p>
                </router-link>
                <div class="flex items-center tooltip-wrapper" @click="openModal">
                    <div v-if="collapsed">
                        <QuestionMarkCircleIcon class="question icon-small" />
                        <span class="clippy-tooltip tooltip-content">Send Request<img class="clippy-white"
                                src="../assets/images/clippy-white.svg" /></span>
                    </div>
                    <div class="flex items-center" v-else>
                        <img class="clippy-gray" src="../assets/images/clippy-gray.svg" />
                        <p class="clippy-request">Send request</p>
                    </div>
                    <ModalRequestForm :isOpen="isOpen" @closeModal="closeModal" />
                </div>
            </div>

            <div class="sidebar-bottom" v-if="user">
                <ChevronDoubleLeftIcon class="collapse-icon icon thick" :class="{ 'rotate-180': collapsed }"
                    @click="toggleSidebar" />
                <div class="user-detail">
                    <div class="initials-icon">{{ initials }}</div>
                    <div v-if="!collapsed" class="user-name-mail">
                        <p class="name">{{ userName }} {{ lastName }}</p>
                        <p>{{ email }}</p>
                    </div>
                    <ArrowRightOnRectangleIcon @click="handleLogout" class="logout icon icon-small" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ArrowRightOnRectangleIcon, ChevronDoubleLeftIcon, PresentationChartBarIcon, AcademicCapIcon, TableCellsIcon, WrenchScrewdriverIcon, ClipboardDocumentCheckIcon, QuestionMarkCircleIcon, Cog6ToothIcon, PlayPauseIcon,CpuChipIcon } from '@heroicons/vue/24/outline'
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import router from '@/router';
import { useInitialsToUserLogo } from '@/composables/useInitialsToUserLogo';
import ModalRequestForm from '@/components/ModalRequestForm.vue';

const store = useStore();
const user = computed(() => { return store.state.authModule.user });
const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');
const isOpen = ref(false);

const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

const closeModal = () => isOpen.value = false;
const openModal = () => {
    if (!collapsed.value) {
        toggleSidebar();
    }
    isOpen.value = true;
};

const returnFirst = (names: string) => {
    const spacePosition = names.indexOf(' ');
    return spacePosition === -1 ? names : names.slice(0, spacePosition);
}
const userName = computed(() => returnFirst(user.value.firstName));
const lastName = computed(() => returnFirst(user.value.lastName));
const email = computed(() => user.value.email)
const initials = computed(() => useInitialsToUserLogo(userName?.value, lastName?.value))
const handleLogout = (async () => {
    await store.dispatch('authModule/logout')
    router.push('/')
})

</script>
<style lang="scss" scoped>
.sidebar {
    background-color: $grey-super-light;
    position: fixed;
    height: 100vh;
    box-shadow: $right-shadow;
    padding-top: 2.5rem;
    border-right: 1px solid $grey-medium;
    width: 280px;
    z-index: 99;

    img {
        padding: 1rem 2rem 2rem;
        cursor: pointer;
    }
    &.collapsed {
        background-color: $one-five-blue;
        width: 80px;

        img {
            padding: 0 0.5rem 2.5rem;
        }

        .clippy-white {
            padding-bottom: 0;
        }
        

        .sidebar-links a {
            justify-content: center;
            padding: 0.3rem;
            margin: 1rem;
            margin-top: 7px;
            margin-bottom: 7px;
            border-radius: 8px;

            svg {
                stroke: #fff;
            }

            &.router-link-active,
            &:hover {
                background-color: $blue-medium;
                transition: background-color 0.3s ease-out;
            }
        }
        .initials-icon {
            background: $blue-medium;
            color: $grey-super-light;
        }
    }
}
.sidebar-content {
    display: flex;
    height: calc(100% - 6rem);
    justify-content: space-between;
    flex-direction: column;
}
.sidebar-links {
    display: flex;
    flex-direction: column;
    color: $font-color-light;

    a {
        padding: 0.8rem 1rem;
        font-weight: 200;

        &.router-link-active {
            background-color: $grey-light;
            font-weight: 400;
        }

        .polymer {
            padding: 0;
            width: 1.5rem;
            height: 1.5rem;
            opacity: 0.7;
        }
    }
}
.sidebar-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    .user-detail {
        border-top: 1px solid $grey-medium;
        display: flex;
        height: auto;
        width: 100%;
        padding: 1rem;
        .user-name-mail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $font-color-super-light;
            font-size: 0.8rem;
            line-height: 1.2;

            .name {
                font-size: 0.9rem;
                font-weight: 500;
            }
        }
    }
}
.logout {
    margin-left: 25px;
    margin-top: 12px;
}
.collapse-icon {
    position: relative;
    top: 1rem;
    left: 260px;
    transition: 0.1s ease-in-out;
    stroke: $one-five-blue;
    background: $grey-super-light;
    border: $border;
    border-radius: 50%;
    padding: 3px;
    width: 2rem;
    height: 2rem;
}
.rotate-180 {
    position: static;
    transform: rotate(180deg);
    margin-left: 22px;
    margin-bottom: 25px;
    stroke: $grey-super-light;
    background: transparent;
    border: none;
    transition: 0.1s ease-in-out;
}

.question {
    stroke: $grey-medium;
    stroke-width: 1.5px;
    padding: 5px;
    margin: 20px 22px;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    &:hover {
        border-radius: 8px;
        background-color: $blue-medium;
        transition: background-color 0.3s ease-out;
    }
}

.clippy-request {
    position: relative;
    bottom: 1.5px;
    right: 40px;
    color: $font-color-light;
    font-weight: 200;
    cursor: pointer;
}

.clippy-gray {
    position: relative;
    top: 7px;
    right: 20px;
    height: 5rem;
    stroke: $grey-super-light;
}

.tooltip-wrapper {
    position: relative;

    .tooltip-content {
        background: $blue-medium;
        text-transform: uppercase;
        bottom: 6%;
        color: $grey-super-light;
        display: block;
        left: 57px;
        opacity: 0;
        padding: 4px 6px;
        pointer-events: none;
        position: absolute;
        width: auto;
        transform: translateY(10px);
        transition: opacity .25s ease-out 1.5s;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        border-radius: 8px;
        font-size: 0.8rem;
        font-weight: 400;
        visibility: hidden;

        &.clippy-tooltip {
            text-align: center;
            bottom: 30%;
            left: 65px;
        }

        &::after {
            border-top: solid transparent 8px;
            border-bottom: solid transparent 8px;
            border-right: solid #2a87d4 8px;
            bottom: 7px;
            content: " ";
            height: 0;
            left: 20px;
            margin-left: -26px;
            position: absolute;
            width: 0;
        }
    }
}

.tooltip-wrapper:hover .tooltip-content {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0px);
}
</style>