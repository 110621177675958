<template>
	<ModalSlots :create-text="onEdit ? 'Update' : 'Create'" :isOpen="isOpen" dialogClass="l-modal"
		@closeModal="onEdit ? $emit('closeEdit') : $emit('closeNew')" @click-create="handleCreateOrUpdate">
		<template v-slot:header>
			<p v-if="onEdit">edit programme version</p>
			<p v-else>new programme version</p>
		</template>

		<template v-slot:body>
			<form class="px-8 pb-16">
				<FormErrorToast v-if="errorsFromMutation" :is-showing="showMutationErrors" :title="errorsFromMutation"
					toastType="warning" />
				<FormErrorToast :is-showing="formSubmited && !formIsValid && !onEdit"
					title="The following fields are mandatory:" :subtitle="missingFields.join(', ')" />
				<div class="grid grid-cols-5 gap-y-2.5 gap-x-4">
					<div class="form-separator col-span-5">
						<p class="form-subtitle">Overview</p>
					</div>
					<div class="col-span-3">
						<label>Programme *</label>
						<template v-if="!onEdit">
							<DropdownSearchableSingleVue v-model="formFields['program'].value"
								:options-list="formInizializationValues['programsOptions']" />
							<ProgramAddNewForm />
						</template>
						<template v-else>
							<input class="w-full" type="text" disabled :value="programName">
						</template>
					</div>
					<div class="col-span-2">
						<label>Version *</label>
						<input class="w-full" type="text" v-model.number="formFields['version'].value">
					</div>
					<div class="col-span-2">
						<label>Creation Date *</label>
						<template v-if="!onEdit">
							<Datepicker class="w-full" :upper-limit="new Date()" :clearable="true" :input-format="'dd-MMM-yyyy'"
							v-model="formFields['creationDate'].value" />
						</template>
						<template v-else>
							<input class="w-full" type="text" disabled :value="moment(creationDate).format('DD MMM YYYY') ">
						</template>
					</div>
					<div class="col-span-3">
						<label>Application <span>(One or more)</span></label>
						<DropdownSearchableMultiple v-if="formInizializationValues['applicationTypesOptions']"
							:options-list="formInizializationValues['applicationTypesOptions']"
							v-model="formFields['applicationTypes'].value" />
					</div>
					<div class="col-span-5 row-span-2 grid mb-3">
						<label>Goal *</label>
						<QuillEditor :content="formFields['goal'].value ? formFields['goal'].value : '<p><br></p>'"
							content-type="html"
							@update:content="content => content == '<p><br></p>' ? formFields['goal'].value = null : formFields['goal'].value = content"
							@ready="onEditorReady($event)" />
					</div>
					<div class="col-span-5 row-span-2 grid">
						<label>Description</label>
						<QuillEditor
							:content="formFields['description'].value ? formFields['description'].value : '<p><br></p>'"
							content-type="html"
							@update:content="content => content == '<p><br></p>' ? formFields['description'].value = null : formFields['description'].value = content"
							@ready="onEditorReady($event)" />
					</div>
					<div class="form-separator col-span-5">
						<p class="form-subtitle">Team</p>
					</div>
					<TeamMembersForm :on-edit="onEdit"
						:team-role-types-options="formInizializationValues['teamRoleTypesOptions']"
						:employees-options="formInizializationValues['employeesOptions']" />
					<div class="form-separator col-span-5">
						<p class="form-subtitle">Properties</p>
					</div>
					<ProgramVersionTargetsForm :on-edit="onEdit" />
				</div>
			</form>
		</template>
	</ModalSlots>
</template>  
  
  
<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import Datepicker from 'vue3-datepicker';
import ModalSlots from '@/components/ModalSlots.vue';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { NEW_PROGRAM_VERSIONS } from '@/grapql/newProgramVersionsQueries';
import { NEW_PROGRAMS_FIELDS } from '@/models/newProgramsFormFields';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableSingleVue from './DropdownSearchableSingle.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import ProgramAddNewForm from '@/components/ProgramAddNewForm.vue';
import TeamMembersForm from '@/components/TeamMembersForm.vue';
import ProgramVersionTargetsForm from '@/components/ProgramVersionTargetsForm.vue';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { QuillEditor } from '@vueup/vue-quill';
import { useStore } from 'vuex';
import moment from "moment";
import { useFormFieldsCreationNewProgramAndVersion, useFormFieldsToEditProgramAndVersion } from '@/composables/useFormFieldsCreateAndEditPrograms';


const props = defineProps({
	onEdit: { type: Boolean, required: false },
	isOpen: { type: Boolean, required: false }
})

const emit = defineEmits(['closeEdit', 'closeNew']);
const store = useStore();

// Inizialize the form
const { result } = useQuery(NEW_PROGRAM_VERSIONS);
const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => {
	return useFormInizializationNewAsset(inizilizedData.value, NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.fieldsToQuery);
});

// Fields for the creation form, excluding programVersionTeam and programVersionTargets
const formFieldOptions = {
	fields: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.formFields,
	base: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.graphqlValue,
	mutation: 'programsCreationModule/setSingleProgramVersionValue',
};

// Fields for the edition form, excluding programVersionTeam and programVersionTargets
const formFieldEditOptions = {
	fields: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS_EDITION.formFields,
	base: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS_EDITION.graphqlValue,
	mutation: 'programVersionDetailModule/updateSingleProgramVersionValue',
};

const programName = computed(() => props.onEdit ? store.state['programVersionDetailModule'].programVersionFromBE.program.name : null)
const creationDate = computed(() => props.onEdit ? store.state['programVersionDetailModule'].programVersionFromBE.creationDate : null)

const formFields = props.onEdit ? useFormFieldsToEditProgramAndVersion(formFieldEditOptions) : useFormFieldsCreationNewProgramAndVersion(formFieldOptions);
const onEditorReady = (event: Event) => { return event };

// Form validation
const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state['programsCreationModule']['programVersion']);
const formIsValid = computed(() => NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.mandatoryFormFields.every(mandatoryField => !!valuesSubmitted.value[mandatoryField]));
const missingFields = computed(() => formIsValid.value ? [] : NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.mandatoryFormFields.filter(mandatoryField => !valuesSubmitted.value[mandatoryField]));
const errorsFromMutation = computed(() => props.onEdit ? store.state['programVersionDetailModule']['errorDuringMutation']['programVersionUpdatePartial'] : store.state['programsCreationModule']['errorDuringMutation']['newProgramVersion']);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const handleCreateOrUpdate = () => props.onEdit ? updateProgramVersion() : createProgramVersion();

const createProgramVersion = async () => {
	formSubmited.value = true;
	if (formIsValid.value) {
		const mutation = NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.createMutation;

		await store.dispatch(mutation)
			.then(() => {
				if (!errorsFromMutation.value) {
					formSubmited.value = false;
					store.commit('programsCreationModule/resetProgramVersionValues');
					emit('closeNew')

				}
			});
	}
}

const updateProgramVersion = async () => {
	formSubmited.value = true;
	const mutation = NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS_EDITION.updateMutation;
	await store.dispatch('programVersionDetailModule/programVersionTargetDelete')
		.then(async () => { await store.dispatch('programVersionDetailModule/programVersionTeamDelete')})
		.then(async () => { await store.dispatch(mutation)})
		.then(() => {
			if (!errorsFromMutation.value) {
				formSubmited.value = false;
				emit('closeEdit');
			}
		});
}

onMounted(() => {
	if (props.onEdit) {
		store.commit('programVersionDetailModule/parseBeValuesForEdition');
		store.commit('programVersionDetailModule/parseBeTeamValuesForEdition');
		store.commit('programVersionDetailModule/parseBeTargetsValuesForEdition');
	}
})
</script>
