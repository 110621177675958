import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 gap-4" }

import { computed,onMounted,PropType,watch } from 'vue';
import { useStore } from 'vuex';
import {useQuery} from '@vue/apollo-composable';
import {CHAT_MESSAGES} from "@/grapql/aiQuery";
import { useEdgesToChatMessages } from '@/composables/aiComposables';
import {  MessageType, ChatKey } from '@/models/aiModels';
import AIChatQuestion from "@/components/ai/AIChatQuestion.vue";
import AIChatAnswer from "@/components/ai/AIChatAnswer.vue";
import AIChatError from "@/components/ai/AIChatError.vue";
import { IChatMessage } from '@/models/aiInterfaces';
import {useRouter} from 'vue-router';



export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatMessages',
  props: {
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
},
  setup(__props) {

const props = __props;
const store = useStore();
const router = useRouter();

const chatMessages= computed<IChatMessage[]>(()=> store.getters['aiChatModule/getChatMessages']);

// load chat messages history
const fetchChatMessages = (chatId: string) => {
    const {onResult , onError} = useQuery(CHAT_MESSAGES,{chatId},{"fetchPolicy":"cache-and-network"});
    
    onResult(async queryResult => {
        // check if chatId exists
        if (queryResult.data && !queryResult?.data?.chat)
        {
            router.push({name: 'AI'});
        }
        else{
            if (queryResult.data && queryResult?.data?.chat?.messages) {
                const messages = useEdgesToChatMessages(chatId, queryResult.data.chat.messages.edges);
                if(messages.length > 0){
                    store.commit('aiChatModule/setChatMessages', messages);
                }
            }
        }
        
    });

    onError((error) => {
        console.log(error);
    });
};

watch(() => props.chatId, (newChatId) => {
    store.commit('aiChatModule/setChatMessages', []);
    fetchChatMessages(newChatId);
});

onMounted(() => {
    fetchChatMessages(props.chatId);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chatMessages.value, (chatMessage, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "chat-message"
          }, [
            (chatMessage.messageType == _unref(MessageType).QUESTION)
              ? (_openBlock(), _createBlock(AIChatQuestion, {
                  key: 0,
                  message: chatMessage,
                  chatKey: __props.chatKey
                }, null, 8, ["message", "chatKey"]))
              : (chatMessage.messageType == _unref(MessageType).ANSWER)
                ? (_openBlock(), _createBlock(AIChatAnswer, {
                    key: 1,
                    message: chatMessage
                  }, null, 8, ["message"]))
                : (chatMessage.messageType == _unref(MessageType).ERROR)
                  ? (_openBlock(), _createBlock(AIChatError, {
                      key: 2,
                      message: chatMessage
                    }, null, 8, ["message"]))
                  : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})