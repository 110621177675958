import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-on-disclosure" }
const _hoisted_2 = {
  key: 0,
  class: "divide-y prop-info"
}
const _hoisted_3 = {
  key: 1,
  class: "mb-4 text-sm italic text-neutral-400"
}

import { AssetPropInterface } from '@/models/assetAssessmentInterfaces';
import { ref } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import AssetProperty from './AssetProperty.vue';
import AssetPropertyAddForm from './AssetPropertyAddForm.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentInDepthProperties',
  props: {
    propsData: {}
  },
  setup(__props: any) {



const onAddition = ref(false);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Disclosure), { defaultOpen: true }, {
    default: _withCtx(({ open }) => [
      _createVNode(_unref(DisclosureButton), { class: "flex w-full py-2 text-left text-sm font-medium disclosure-button" }, {
        default: _withCtx(() => [
          _createVNode(_unref(ChevronRightIcon), {
            class: _normalizeClass([open ? 'chevron-rotate' : '', "h-5 w-5"])
          }, null, 8, ["class"]),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "section-title" }, "Properties", -1))
        ]),
        _: 2
      }, 1024),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(PlusCircleIcon), {
          class: "icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onAddition.value = true))
        })
      ]),
      (onAddition.value)
        ? (_openBlock(), _createBlock(AssetPropertyAddForm, {
            key: 0,
            onCancelAddition: _cache[1] || (_cache[1] = ($event: any) => (onAddition.value = false))
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(DisclosurePanel), { class: "pl-4" }, {
        default: _withCtx(() => [
          (!!_ctx.propsData.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propsData, (prop) => {
                  return (_openBlock(), _createBlock(AssetProperty, {
                    key: prop.id,
                    "asset-prop": prop
                  }, null, 8, ["asset-prop"]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_3, " No properties assigned to this technology yet "))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})