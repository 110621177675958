import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full mt-3"
}
const _hoisted_2 = { class: "flex justify-end w-full mt-3" }
const _hoisted_3 = { class: "ml-1flex text-xs text-super-light uppercase mb-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex items-center justify-between" }
const _hoisted_7 = { class: "name mb-3" }
const _hoisted_8 = {
  key: 0,
  class: "value"
}
const _hoisted_9 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_10 = {
  key: 0,
  class: "value"
}
const _hoisted_11 = {
  key: 1,
  class: "value"
}
const _hoisted_12 = {
  key: 2,
  class: "value"
}
const _hoisted_13 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_14 = {
  key: 1,
  class: "value"
}
const _hoisted_15 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_16 = {
  key: 0,
  class: "w-full"
}
const _hoisted_17 = ["innerHTML"]

import { AssetPropInterface } from '@/models/assetAssessmentInterfaces';
import { ref } from 'vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetPropertyEditForm from './AssetPropertyEditForm.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AssetProperty',
  props: {
    assetProp: {}
  },
  setup(__props: any) {



const onEdit = ref(null);


return (_ctx: any,_cache: any) => {
  return (!onEdit.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!onEdit.value)
            ? (_openBlock(), _createBlock(_unref(PencilSquareIcon), {
                key: 0,
                class: "icon",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (onEdit.value = true))
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.assetProp.prop.propCategory.name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.assetProp.prop.propCategory.name), 1))
            : _createCommentVNode("", true),
          (_ctx.assetProp.prop.propCategory.propClass.name && _ctx.assetProp.prop.propCategory.propClass.name != 'Unknown')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " - " + _toDisplayString(_ctx.assetProp.prop.propCategory.propClass.name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.assetProp.prop.name), 1),
          (_ctx.assetProp.valueType)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.assetProp.valueType.name == 'Between')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_ctx.assetProp.value1) + " - " + _toDisplayString(_ctx.assetProp.value2) + " ", 1),
                      (_ctx.assetProp.valueUnit)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.assetProp.valueUnit.abbreviation), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.assetProp.valueType.name == 'Boolean')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.assetProp.value1 && _ctx.assetProp.value1 == 'true')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_10, "Yes"))
                          : (_openBlock(), _createElementBlock("p", _hoisted_11, "No"))
                      ], 64))
                    : (_openBlock(), _createElementBlock("p", _hoisted_12, [
                        _createTextVNode(_toDisplayString(_ctx.assetProp.valueType.symbol) + " " + _toDisplayString(_ctx.assetProp.value1) + " ", 1),
                        (_ctx.assetProp.valueUnit)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.assetProp.valueUnit.abbreviation), 1))
                          : _createCommentVNode("", true)
                      ]))
              ], 64))
            : (_openBlock(), _createElementBlock("p", _hoisted_14, [
                _createTextVNode(_toDisplayString(_ctx.assetProp.value1) + " ", 1),
                (_ctx.assetProp.valueUnit)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.assetProp.valueUnit.abbreviation), 1))
                  : _createCommentVNode("", true)
              ]))
        ]),
        (_ctx.assetProp.extraInformation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-xs text-super-light" }, "Extra Information", -1)),
              _createElementVNode("p", {
                class: "value extra-info",
                innerHTML:  _ctx.assetProp.extraInformation
              }, null, 8, _hoisted_17)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(AssetPropertyEditForm, {
        key: 1,
        "asset-prop": _ctx.assetProp,
        onCancelEdition: _cache[1] || (_cache[1] = ($event: any) => (onEdit.value = false))
      }, null, 8, ["asset-prop"]))
}
}

})