import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 grid-flow-row gap-4" }
const _hoisted_2 = { class: "grid grid-flow-row gap-4" }
const _hoisted_3 = { class: "bg-white border p-5 rounded-md min-h-10" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "bg-white border p-5 rounded-md" }
const _hoisted_6 = { class: "grid grid-flow-row gap-4" }
const _hoisted_7 = { class: "bg-white border p-5 rounded-md min-h-40" }
const _hoisted_8 = { class: "bg-white border p-5 rounded-md min-h-48" }

import { computed } from 'vue';
import ProductBriefClientCard from '@/components/ProductBriefClientCard.vue';
import ProductBriefMarketsCard from '@/components/ProductBriefMarketsCard.vue';
import { MaterialInterface, ProductBriefInterface } from '@/models/productBriefsInterface';
import MaterialTargetTable from './MaterialTargetTable.vue';
import { TargetInterface } from '@/models/programVersionsInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefOverview',
  props: {
    productBrief: {}
  },
  setup(__props: any) {

const props = __props;

const productBriefAllTargets = computed<TargetInterface[]>(() => props.productBrief.targets.edges.map((edge) => edge.node) ?? null);
const productBriefTargets = computed<TargetInterface[]>(() => productBriefAllTargets.value.filter(target => !!target.targetProp));
const productBriefMaterials = computed<MaterialInterface[]>(() => props.productBrief.materials.edges.map((edge) => edge.node) ?? null);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-medium text-sm mb-3" }, "Objetive", -1)),
        _createElementVNode("p", {
          class: "text-sm text-neutral-500 font-light",
          innerHTML: _ctx.productBrief.objective
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(ProductBriefClientCard, {
          "product-brief-client": _ctx.productBrief.client
        }, null, 8, ["product-brief-client"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bg-white border p-5 rounded-md min-h-40" }, [
        _createElementVNode("p", { class: "uppercase font-medium text-sm mb-3" }, "Files")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(ProductBriefMarketsCard, {
        markets: _ctx.productBrief.markets
      }, null, 8, ["markets"]),
      _createElementVNode("div", _hoisted_7, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "uppercase font-medium text-sm mb-3" }, "Targets", -1)),
        _createVNode(MaterialTargetTable, {
          "target-data": productBriefTargets.value,
          "is-product-brief": true
        }, null, 8, ["target-data"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "uppercase font-medium text-sm mb-3" }, "Materials", -1)),
        _createVNode(MaterialTargetTable, {
          "material-data": productBriefMaterials.value,
          "is-product-brief": true
        }, null, 8, ["material-data"])
      ])
    ])
  ]))
}
}

})