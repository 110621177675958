import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, vModelRadio as _vModelRadio, vModelText as _vModelText, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "col-span-1" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "col-span-2" }
const _hoisted_4 = {
  key: 0,
  class: "col-span-2"
}
const _hoisted_5 = { class: "col-span-1" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 1,
  class: "col-span-1"
}
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = {
  key: 2,
  class: "flex justify-between"
}
const _hoisted_10 = {
  key: 0,
  class: "w-1/2"
}
const _hoisted_11 = { class: "col-span-1" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["value"]
const _hoisted_14 = {
  key: 3,
  class: "col-span-1"
}
const _hoisted_15 = {
  key: 4,
  class: "col-span-1 mb-12"
}
const _hoisted_16 = {
  key: 5,
  class: "col-span-1"
}
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 6,
  class: "col-span-5"
}
const _hoisted_19 = {
  key: 0,
  class: "font-medium"
}

import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { ProgramVersionTargetsInterface } from '@/models/programVersionsInterface';
import { computed, ref, onMounted, watch } from 'vue';
import { CheckIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import Datepicker from 'vue3-datepicker';
import DropdownSearchableSingleVue from '@/components/DropdownSearchableSingle.vue'
import moment from 'moment';
import { useStore } from 'vuex';
import { PropWithRecommendationInterface } from '@/models/assetAssessmentInterfaces';

export interface ValueTypesInterface {
    id: number,
    name: string,
    symbol: string,
    description: string
}

export interface UnitTypesInterface {
    id: number
    name: string,
    abbreviation: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionTargetFormFields',
  props: {
    onEdit: { type: Boolean },
    targetToEdit: {},
    units: {},
    properties: {},
    propsWithRecommendation: {},
    valueTypes: {},
    targetCategoryTypes: {}
  },
  emits: ['newTarget', 'editedTarget'],
  setup(__props: any) {

const props = __props;



const store = useStore();
const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

const isValueTypeBetween = (targetValueId: number) => props.valueTypes.find(vt => vt.id == targetValueId).name == 'Between';
const isValueTypeBoolean = (targetValueId: number) => props.valueTypes.find(vt => vt.id == targetValueId).name == 'Boolean';

const target = ref({
    id: null,
    creationDate: new Date,
    targetCategoryType: null,
    targetProp: null,
    targetValueType: null,
    targetValue1: null,
    targetValue2: null,
    targetValueUnit: null,
    currentValue: null, 
})

// Validate Form
const targetIsComplete = computed<boolean>(() => target.value.targetValue1 && target.value.targetCategoryType && target.value.targetProp && target.value.targetValue1 && target.value.targetValueType);

const showDisclaimerBetween = computed<boolean>(() =>
    target.value.targetValueType && isValueTypeBetween(target.value.targetValueType) && (!target.value.targetValue2 || target.value.targetValue2 < target.value.targetValue1)
);

watch(() => target.value.targetProp, () => {
	if(target.value.targetProp && !props.targetToEdit) {
		const propSelected = props.propsWithRecommendation.find(property => property.id == target.value.targetProp);
      if (propSelected && propSelected.baseValueType) {
         target.value.targetValueType =  propSelected.baseValueType.id;
      }
      if (propSelected && propSelected.baseUnitType) { target.value.targetValueUnit = propSelected.baseUnitType.id;}
   }
})

onMounted(() => {
    if (props.targetToEdit) {
        target.value = {
            id: props.targetToEdit ? props.targetToEdit.id : null,
            creationDate: props.targetToEdit ? moment(props.targetToEdit.creationDate).toDate() : new Date,
            targetCategoryType: props.targetToEdit ? props.targetToEdit.targetCategoryType : null,
            targetProp: props.targetToEdit ? props.targetToEdit.targetProp : null,
            targetValueType: props.targetToEdit ? props.targetToEdit.targetValueType : null,
            targetValue1: props.targetToEdit ? props.targetToEdit.targetValue1 : null,
            targetValue2: props.targetToEdit ? props.targetToEdit.targetValue2 : null,
            targetValueUnit: props.targetToEdit ? props.targetToEdit.targetValueUnit : null,
            currentValue: props.targetToEdit ? props.targetToEdit.currentValue : null, 
        }
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[13] || (_cache[13] = _createElementVNode("label", null, "Category Type", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((target.value.targetCategoryType) = $event)),
        class: "w-full"
      }, [
        _cache[12] || (_cache[12] = _createElementVNode("option", { value: null }, null, -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetCategoryTypes, (category) => {
          return (_openBlock(), _createElementBlock("option", {
            key: category.id,
            value: category.id
          }, _toDisplayString(category.name), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, target.value.targetCategoryType]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[14] || (_cache[14] = _createElementVNode("label", null, "Property", -1)),
      _createVNode(DropdownSearchableSingleVue, {
        modelValue: target.value.targetProp,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((target.value.targetProp) = $event)),
        "options-list": _ctx.properties
      }, null, 8, ["modelValue", "options-list"])
    ]),
    (!_ctx.onEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({'col-span-1': !_ctx.onEdit, 'col-span-2': _ctx.onEdit})
    }, [
      _cache[15] || (_cache[15] = _createElementVNode("label", null, "Creation Date", -1)),
      _createVNode(_unref(Datepicker), {
        class: "w-full",
        "upper-limit": new Date(),
        "input-format": 'dd-MMM-yyyy',
        modelValue: target.value.creationDate,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((target.value.creationDate) = $event))
      }, null, 8, ["upper-limit", "modelValue"])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _cache[16] || (_cache[16] = _createElementVNode("label", null, "Value Type", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((target.value.targetValueType) = $event)),
        class: "w-full mb-3"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueTypes, (valueType) => {
          return (_openBlock(), _createElementBlock("option", {
            key: valueType.id,
            value: valueType.id
          }, _toDisplayString(valueType.description), 9, _hoisted_6))
        }), 128))
      ], 512), [
        [_vModelSelect, target.value.targetValueType]
      ])
    ]),
    (target.value.targetValueType && isValueTypeBoolean(target.value.targetValueType))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[19] || (_cache[19] = _createElementVNode("label", null, "Value", -1)),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: "yes",
              value: "true",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((target.value.targetValue1) = $event))
            }, null, 512), [
              [_vModelRadio, target.value.targetValue1]
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("label", {
              for: "yes",
              class: "mt-2 mr-3"
            }, "Yes", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: "No",
              value: "false",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((target.value.targetValue1) = $event))
            }, null, 512), [
              [_vModelRadio, target.value.targetValue1]
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              for: "No",
              class: "mt-2"
            }, "No", -1))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: _normalizeClass([target.value.targetValueType && isValueTypeBetween(target.value.targetValueType) ? 'w-1/2' : 'w-full', "mr-1"])
          }, [
            _cache[20] || (_cache[20] = _createElementVNode("label", null, "Value", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((target.value.targetValue1) = $event)),
              class: "w-full"
            }, null, 512), [
              [_vModelText, target.value.targetValue1]
            ])
          ], 2),
          (target.value.targetValueType && isValueTypeBetween(target.value.targetValueType))
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[21] || (_cache[21] = _createElementVNode("label", null, "Value 2", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((target.value.targetValue2) = $event)),
                  class: "w-full mb-3"
                }, null, 512), [
                  [_vModelText, target.value.targetValue2]
                ])
              ]))
            : _createCommentVNode("", true)
        ])),
    _createElementVNode("div", _hoisted_11, [
      (!(target.value.targetValueType && isValueTypeBoolean(target.value.targetValueType)))
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _cache[23] || (_cache[23] = _createElementVNode("label", null, "Unit", -1)),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((target.value.targetValueUnit) = $event)),
              class: "w-full"
            }, [
              _cache[22] || (_cache[22] = _createElementVNode("option", { value: null }, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.units, (unitOption) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: unitOption.id,
                  value: unitOption.id
                }, _toDisplayString(unitOption.abbreviation) + " - " + _toDisplayString(unitOption.name), 9, _hoisted_13))
              }), 128))
            ], 512), [
              [_vModelSelect, target.value.targetValueUnit]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.onEdit && isUserAllowToSeeExtraInfo.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _cache[24] || (_cache[24] = _createElementVNode("label", null, "Current Value", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((target.value.currentValue) = $event)),
            class: "w-full"
          }, null, 512), [
            [_vModelText, target.value.currentValue]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.targetToEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("button", {
            class: "black-button-with-icon w-full",
            onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.$emit('editedTarget', target.value)), ["prevent"]))
          }, [
            _createVNode(_unref(CheckIcon), { class: "icon icon-x-small light thick mr-2" }),
            _cache[25] || (_cache[25] = _createTextVNode(" Save this changes "))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("button", {
            class: "black-button-with-icon",
            onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.$emit('newTarget', target.value)), ["prevent"])),
            disabled: !targetIsComplete.value
          }, [
            _createVNode(_unref(CheckIcon), { class: "icon icon-x-small light thick mr-2" }),
            _cache[26] || (_cache[26] = _createTextVNode(" Add this property "))
          ], 8, _hoisted_17)
        ])),
    (targetIsComplete.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", {
            class: _normalizeClass(["disclaimer-target items-center flex mb-7", {'mt-3': !_ctx.onEdit, '-mt-6': _ctx.onEdit}])
          }, [
            _createVNode(_unref(InformationCircleIcon), { class: "icon icon-small thick mr-2" }),
            _createElementVNode("div", null, [
              (showDisclaimerBetween.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_19, "Remember to fill both values and the second one should be bigger than the first one and then"))
                : _createCommentVNode("", true),
              _cache[27] || (_cache[27] = _createElementVNode("p", null, " Click the button to add this property to this program version", -1))
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})