import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "uppercase font-medium text-sm mb-3" }
const _hoisted_2 = { class: "grid grid-cols-5 gap-3 mt-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col space-y-3" }
const _hoisted_5 = { class: "uppercase font-normal text-sm px-3 py-1 bg-black text-white rounded-full inline-block" }
const _hoisted_6 = { class: "ml-3 font-light text-sm italic text-neutral-400" }
const _hoisted_7 = { key: 0 }

import { productBriefClientInterface } from '@/models/productBriefsInterface';



export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefClientCard',
  props: {
    productBriefClient: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.productBriefClient.nickname), 1),
    (_ctx.productBriefClient.sdgs.edges.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "border-t border-b uppercase font-normal py-1 text-sm mt-5" }, "SDGS", -1)),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productBriefClient.sdgs.edges, (sdg) => {
              return (_openBlock(), _createElementBlock("img", {
                key: sdg.node.id,
                src: require(`@/assets/images/SDG/E-WEB-Goal-${sdg.node.id}.png`),
                class: "cols-span-1 opacity-70 w-full max-w-28"
              }, null, 8, _hoisted_3))
            }), 128))
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.productBriefClient.sustainableClaims.edges.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "border-t border-b uppercase font-normal py-1 text-sm mb-3 mt-6" }, "Sustainable Claims", -1)),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productBriefClient.sustainableClaims.edges, (sc) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sc.node.id,
                class: "flex items-center"
              }, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(sc.node.name), 1),
                _createElementVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(sc.node.propCategory.name) + " ", 1),
                  (sc.node.propCategory.propClass.name != 'Unknown')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "/ " + _toDisplayString(sc.node.propCategory.propClass.name), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})