import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, vModelRadio as _vModelRadio, toDisplayString as _toDisplayString, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex justify-end mb-6 -mt-2" }
const _hoisted_3 = {
  key: 0,
  class: "mb-12"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex flex-wrap" }
const _hoisted_6 = ["value", "id"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "mt-6 pl-2 pr-8" }
const _hoisted_10 = {
  key: 1,
  class: "border bg-charcoal flex pl-2 pr-6 py-4 text-white items-center w-fit rounded-lg mt-4 ml-4"
}
const _hoisted_11 = { class: "assets-container" }
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = {
  key: 0,
  class: "flex trash"
}
const _hoisted_14 = { class: "flex flex-col" }
const _hoisted_15 = { class: "name" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_18 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_19 = { class: "flex flex-wrap" }
const _hoisted_20 = ["value", "id"]
const _hoisted_21 = ["for"]
const _hoisted_22 = { class: "mt-6 mb-20 pl-2 pr-8" }

import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ModalSlots from "@/components/ModalSlots.vue";
import { XCircleIcon, ArrowUturnLeftIcon, TrashIcon, FaceFrownIcon } from '@heroicons/vue/24/outline'
import { useQuery } from '@vue/apollo-composable';
import { ALL_ASSETS, ASSETS_COMPARISON_CRITERIA_OPTIONS, LAYER_TYPES } from '@/grapql/assetsComparisonCriteriaOptions';
import DropdownSearchableMultiple from './DropdownSearchableMultiple.vue';
import router from '@/router';
import {AssetSimpreInterface, LayerTypeInterface, PropInterface} from '@/models/assetsComparisonTableInterfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsComparisonCriteriaModal',
  props: {
    action: {
        type: String,
        default: 'compare',
        required: false
    }, 
},
  setup(__props) {

const props = __props
    
const store = useStore();

const isOpen = computed(() => store.state.assetsModule.comparisonCriteriaModalIsOpen);
const closeModal = () => store.commit('assetsModule/closeComparisonCriteriaModal');


const { result } = useQuery(ASSETS_COMPARISON_CRITERIA_OPTIONS);
const queryResult = computed(() => result?.value ?? null);

const properties = computed<PropInterface[]>(() => queryResult.value && queryResult.value.props.edges.length ? queryResult.value.props.edges.map((edge: { node: PropInterface; }) => edge.node) : null)


const propertiesClassNames = computed(() => {
    const classNames = properties.value ? properties.value.map(prop => prop.propCategory.propClass.name.toLowerCase()) : null
    return [...new Set(classNames)]
})

const selectedClassName = ref([]);
const filteredProperties = computed(() => selectedClassName.value.length == 0 ? properties.value : properties.value.filter(property => selectedClassName.value.includes(property.propCategory.propClass.name) ));

const selectedPropertiesIds = computed(() => store.getters['assetsModule/getComparisonProperties'].map((property: PropInterface) => property.id));
const selectedProperties = ref(selectedPropertiesIds.value)

// Variables to handle new assets in the comparison table 
const showAditionOptions = ref(false);
const selectedLayerType = ref(null);
const enabled = ref(false)

// Layer Types
const { result:layerResults } = useQuery(LAYER_TYPES, null, () => ({ enabled: enabled.value,}));
const layerQueryResult = computed(() => layerResults?.value ?? null);
const layerTypes = computed<LayerTypeInterface[]>(() => layerQueryResult?.value && layerQueryResult.value?.layerTypes ? layerQueryResult.value?.layerTypes?.edges.map((edge: { node: LayerTypeInterface; }) => edge.node) : null)

const handleShowAssetAditionOptions = () => {
    enabled.value = true;
    showAditionOptions.value = true;
}

// Assets section
const assetsFromStore = computed(() => props.action != 'compare' ? store.getters['assetsModule/getAssetsToCompare'] : null);
const assets = ref(assetsFromStore.value);

// Watch for changes in the store 
watch(assetsFromStore, (newAssetsFromStore) => {
    assets.value = newAssetsFromStore
})

const assetsToDelete = ref([]);
const assetsToBeAdded = ref([]);

const enableAssetQuery = ref(false);
const queryOption = ref({ filter: {}});

const { result:assetsResult, refetch:refetchAssets } = useQuery(ALL_ASSETS, queryOption.value, () => ({ enabled: enableAssetQuery.value,}) );
const assetsQueryResult = computed(() => assetsResult?.value ?? null);
const allAssetsFromBE = computed<AssetSimpreInterface[]>(() => assetsQueryResult?.value && assetsQueryResult.value?.otherTechnologies ? assetsQueryResult.value?.otherTechnologies?.edges.map((edge: { node: AssetSimpreInterface; }) => edge.node).filter((asset: AssetSimpreInterface) => !assets.value.includes(asset)) : null)
const assetsForDropdown = computed(() => allAssetsFromBE?.value ? allAssetsFromBE.value.map(({id, nickname, title}) => ({id, name: nickname, title})) : null)

const getAssetsToAdd = () => {
    queryOption.value.filter = {targetLayers: {name: {in: selectedLayerType.value}}};
    enableAssetQuery.value = true;
    refetchAssets(); 
}

const cancelAddAssets = () => {
    assetsToBeAdded.value = [];
    selectedLayerType.value = null;
    showAditionOptions.value = false
}

const handleAssetComparison = () => {
    const propertiesToSave = computed(() => properties.value.filter(property => selectedProperties.value.includes(property.id)));
    
    if (props.action != 'compare') {
        const assetsToAdd = computed(() => assetsToBeAdded.value.length > 0 ? allAssetsFromBE.value.filter(asset => assetsToBeAdded.value.includes(asset.id)) : null)
        const assetsWithoutRemovals = computed(() => assets.value.filter((asset: any) => !assetsToDelete.value.includes(asset)));
        const assetsToSaveInStore = computed(() => assetsToAdd.value ? assetsWithoutRemovals.value.concat(assetsToAdd.value) : assetsWithoutRemovals.value)
        store.commit('assetsModule/setSelectedAssetForComparison', {assetsToCompare: assetsToSaveInStore.value})
    }
    
    store.commit('assetsModule/setSelectedPropertiesForComparison', { properties: propertiesToSave.value} );
    store.commit('assetsModule/closeComparisonCriteriaModal');
    router.push({name: 'TechnologyAssetsComparisonView'});
    assetsToDelete.value = [];
    cancelAddAssets();
}

const tableName = ref('');
const handlePublish = () => console.log('publish');
const handleSaveForLater = () => console.log('save for later');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    show: isOpen.value,
    onCloseModal: closeModal,
    dialogClass: "l-modal"
  }, {
    header: _withCtx(() => _cache[5] || (_cache[5] = [
      _createElementVNode("p", null, "comparison criteria selection", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.action == 'publish' || __props.action == 'save')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "form-separator" }, [
                _createElementVNode("p", { class: "form-subtitle" }, "table name")
              ], -1)),
              _cache[7] || (_cache[7] = _createElementVNode("label", null, "Name *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-1/2",
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tableName).value = $event))
              }, null, 512), [
                [_vModelText, tableName.value]
              ])
            ], 64))
          : _createCommentVNode("", true),
        (__props.action != 'compare')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "form-separator" }, [
                _createElementVNode("p", { class: "form-subtitle" }, "technology assets")
              ], -1)),
              _createElementVNode("div", _hoisted_2, [
                (!showAditionOptions.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "button bg-danger-light text-white font-medium",
                      onClick: handleShowAssetAditionOptions
                    }, "Add Assets"))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "button bg-charcoal text-white font-medium",
                      onClick: cancelAddAssets
                    }, "Cancel"))
              ]),
              (showAditionOptions.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (layerTypes.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _cache[8] || (_cache[8] = _createElementVNode("label", { class: "pl-2 mb-2" }, "Please select the type of tehcnology you are working with", -1)),
                          _createElementVNode("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(layerTypes.value, (layer) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: layer.id
                              }, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "radio",
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedLayerType).value = $event)),
                                  value: layer.name,
                                  id: layer.name,
                                  class: "hidden peer",
                                  onChange: getAssetsToAdd
                                }, null, 40, _hoisted_6), [
                                  [_vModelRadio, selectedLayerType.value]
                                ]),
                                _createElementVNode("label", {
                                  for: layer.name,
                                  class: "transition-colors duration-200 ease-in-out peer-checked:bg-black peer-checked:text-white cursor-pointer border m-1 px-2 py-1 rounded-lg"
                                }, _toDisplayString(layer.name), 9, _hoisted_7)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (assetsForDropdown.value && selectedLayerType.value)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (assetsForDropdown.value.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "mb-2" }, "Select the technology assets you want to add to the table:", -1)),
                                  _createVNode(DropdownSearchableMultiple, {
                                    "options-list": assetsForDropdown.value,
                                    modelValue: assetsToBeAdded.value,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((assetsToBeAdded).value = $event))
                                  }, null, 8, ["options-list", "modelValue"])
                                ])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_unref(FaceFrownIcon), { class: "icon icon-m internal-pannel mr-3" }),
                                _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                                  _createElementVNode("p", null, "No technology assets found "),
                                  _createElementVNode("p", { class: "text-xs font-light" }, "Please choose another type of layer")
                                ], -1))
                              ]))
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assets.value, (asset, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["asset-card shadow-md overflow-hidden", {'to-delete': assetsToDelete.value.includes(asset)}])
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      (assetsToDelete.value.includes(asset))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_unref(TrashIcon), { class: "icon icon-small" })
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("p", _hoisted_15, _toDisplayString(asset.nickname), 1),
                        _createElementVNode("p", {
                          class: "text-ellipsis overflow-hidden",
                          innerHTML: asset.title
                        }, null, 8, _hoisted_16)
                      ])
                    ]),
                    (assetsToDelete.value.includes(asset))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createVNode(_unref(ArrowUturnLeftIcon), {
                            class: "icon icon-small ml-3",
                            onClick: ($event: any) => (assetsToDelete.value.splice(assetsToDelete.value.indexOf(asset), 1))
                          }, null, 8, ["onClick"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createVNode(_unref(XCircleIcon), {
                            class: "icon icon-small",
                            onClick: ($event: any) => (assetsToDelete.value.push(asset))
                          }, null, 8, ["onClick"])
                        ]))
                  ], 2))
                }), 128))
              ])
            ], 64))
          : _createCommentVNode("", true),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "form-separator" }, [
          _createElementVNode("p", { class: "form-subtitle" }, "properties")
        ], -1)),
        (properties.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (propertiesClassNames.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", { class: "pl-2 mb-2" }, "You can filter by properties categories to reduce the dropdown list:", -1)),
                    _createElementVNode("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(propertiesClassNames.value, (category) => {
                        return (_openBlock(), _createElementBlock("div", { key: category }, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedClassName).value = $event)),
                            value: category,
                            id: category,
                            class: "hidden peer"
                          }, null, 8, _hoisted_20), [
                            [_vModelCheckbox, selectedClassName.value]
                          ]),
                          _createElementVNode("label", {
                            for: category,
                            class: "transition-colors duration-200 ease-in-out peer-checked:bg-black peer-checked:text-white cursor-pointer border m-1 px-2 py-1 rounded-lg"
                          }, _toDisplayString(category), 9, _hoisted_21)
                        ]))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_22, [
                _cache[13] || (_cache[13] = _createElementVNode("label", { class: "mb-2" }, "Select the properties yo want to compare:", -1)),
                _createVNode(DropdownSearchableMultiple, {
                  "options-list": filteredProperties.value,
                  modelValue: selectedProperties.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedProperties).value = $event))
                }, null, 8, ["options-list", "modelValue"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      (__props.action == 'save')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "button bg-stone-800 text-white",
            onClick: handleSaveForLater
          }, "save for later"))
        : (__props.action == 'publish')
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "button bg-stone-800 text-white",
              onClick: handlePublish
            }, _toDisplayString(__props.action), 1))
          : (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "button bg-stone-800 text-white",
              onClick: handleAssetComparison
            }, "compare"))
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})