import gql from "graphql-tag";

export const ASSET_ASSESSMENT_IN_DEPTH = gql`
query getAssessmentInDepth($id: ID!) {
    asset(id:$id) {
        assetMaterials {
            edges {
                node {
                    id
                    notes
                    blendPercentage
                    thickness
                    thicknessUnit {
                        id
                        abbreviation
                        name
                    }
                    material {
                        id
                        name
                        acronym
                        className
                        category
                        description
                        isFdaApprovedFcm
                        sourcing
                        price
                        priceUnit {
                            abbreviation
                            name
                        }
                        productionCapacity
                        productionCapacityUnit {
                            abbreviation
                            name
                        }
                        sustainabilityType {
                            id
                            name
                        }
                    }
                }
            }
        }
        assetProcessingTechniques {
            edges {
                node{
                    id
                    numberOfRuns
                    processingTechnique {
                        id 
                        name 
                        description 
                        category
                    }
                    assetProcessingTechniqueProperties{
                        edges{
                            node{
                                id
                                processingTechniqueProperty {
                                    id
                                    name
                                    category
                                }
                                value1
                                value2
                                valueUnit {
                                    id
                                    name
                                    abbreviation
                                }
                                valueType {
                                    id
                                    name
                                    symbol
                                    description
                                }
                                extraInformation
                            }
                        }
                    }
                }
            }
        }
        # assetQuantitativeProperties {
        #     edges {
        #         node {
        #             id
        #             extraInformation
        #             value1
        #             value2
        #             valueType {
        #                 id
        #                 name
        #                 symbol
        #                 description
        #             }
        #             valueUnit {
        #                 id
        #                 name
        #                 abbreviation
        #             }
        #             quantitativeProperty {
        #                 id
        #                 name
        #                 category
        #                 description
        #             }
        #         }
        #     }
        # }
        assetProps {
            edges {
                node {
                    id
                    prop {
                        id
                        name
                        propCategory {
                            id
                            name
                            propClass {
                                id
                                name
                            }
                        }
                    }
                    extraInformation
                    value1
                    value2
                    valueType {
                        id
                        name
                        symbol
                        description
                    }
                    valueUnit {
                        id
                        name
                        abbreviation
                        description            
                    }
                }
            }
        }
                
    }
    
    
}`
