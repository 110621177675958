import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "program-detail-content" }
const _hoisted_2 = { class: "main-cards" }
const _hoisted_3 = { class: "side-cards" }
const _hoisted_4 = { class: "program-detail-content" }
const _hoisted_5 = { class: "main-cards" }
const _hoisted_6 = { class: "side-cards" }

import { computed } from 'vue';
import AvatarsActivity from './AvatarsActivity.vue';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import ProgramVersionTargetsCard from './ProgramVersionTargetsCard.vue';
import ProgramApplicationCard from './ProgramApplicationCard.vue'
import ProgramTechniquesCard from './ProgramTechniquesCard.vue';
import ProgramLayersDesign from './ProgramLayersDesign.vue';
import ProgramVersionGoalDescriptionCard from './ProgramVersionGoalDescriptionCard.vue';
import { useInitialsToUserLogo } from '@/composables/useInitialsToUserLogo';
import moment from 'moment';
import { ApplicationTypesInterface, ProgramVersionTeamInterface, TargetInterface } from '@/models/programVersionsInterface';
import { programMockupIterface } from '@/models/programsInterface';
import ProgramVersionApplicationsCard from './ProgramVersionApplicationsCard.vue';
import ProgramVersionTeamCard from './ProgramVersionTeamCard.vue'
import ProgramVersionDesignsCard from './ProgramVersionDesignsCard.vue';
import ProgramVersionDetailHeader from './ProgramVersionDetailHeader.vue';
// import ProgramTechnologiesCard from './ProgramTechnologiesCard.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramDetailOverview',
  props: {
    programDetailInfoMockup: {},
    programVersion: {},
    isMockup: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const usersActivity = computed(() => {
    const parsedUsers = [];
    if (!props.isMockup) {
        const createdBy = {
            title: 'Created by',
            date: props.programVersion.creationDate ? moment(props.programVersion.creationDate).format('DD MMM YYYY') : null,
            personName: props.programVersion.createdBy.username,
            initials: props.programVersion.createdBy.firstName && props.programVersion.createdBy.lastName ? useInitialsToUserLogo(props.programVersion?.createdBy.firstName, props.programVersion?.createdBy.lastName) : "A"
        }
        parsedUsers.push(createdBy);
        if(props.programVersion.updatedBy) {
            const updatedAt = {
                title: 'Updated by',
                date: props.programVersion.updatedAt ? moment(props.programVersion.updatedAt).format('DD MMM YYYY') : null,
                personName: props.programVersion.updatedBy.username,
                initials: props.programVersion.updatedBy.firstName && props.programVersion.updatedBy.lastName ? useInitialsToUserLogo(props.programVersion?.updatedBy.firstName, props.programVersion?.updatedBy.lastName) : "A"
            }
            parsedUsers.push(updatedAt)
        }
    }
    return parsedUsers;
})

const team = computed<ProgramVersionTeamInterface[]>(() => props.programVersion.team.edges.length ? props.programVersion.team.edges.map((edge) => edge.node) : []);

const applications = computed<ApplicationTypesInterface[]>(() => props.programVersion.applicationTypes.edges.length ? props.programVersion.applicationTypes.edges.map((edge) => edge.node) : []);

const allTargets = computed<TargetInterface[]>(() => props.programVersion.targets.edges.length ? props.programVersion.targets.edges.map((edge) => edge.node) : [])
// Remove targets with null values as props due to harmonization
const targets = computed<TargetInterface[]>(() => allTargets.value.filter(target => target.targetProp));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.programDetailInfoMockup)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(TitleGoBackHeader, {
            title: _ctx.programDetailInfoMockup.version
          }, null, 8, ["title"]),
          _createVNode(AvatarsActivity, {
            "users-activity": _ctx.programDetailInfoMockup.leadTeam,
            orientation: "left",
            class: "avatars"
          }, null, 8, ["users-activity"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(ProgramVersionTargetsCard, {
                "program-targets": _ctx.programDetailInfoMockup.targets,
                "product-brief": _ctx.programDetailInfoMockup.productBrief
              }, null, 8, ["program-targets", "product-brief"]),
              _createVNode(ProgramLayersDesign, {
                "layers-info": _ctx.programDetailInfoMockup.layersInfo,
                "alternative-designs": _ctx.programDetailInfoMockup.prototypesDesignsInfo,
                "program-targets": _ctx.programDetailInfoMockup.targets
              }, null, 8, ["layers-info", "alternative-designs", "program-targets"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(ProgramApplicationCard, {
                description: _ctx.programDetailInfoMockup.description,
                application: _ctx.programDetailInfoMockup.application,
                "product-img": _ctx.programDetailInfoMockup.productImage
              }, null, 8, ["description", "application", "product-img"]),
              _createVNode(ProgramTechniquesCard, {
                "production-techniques": _ctx.programDetailInfoMockup.productionTechniques
              }, null, 8, ["production-techniques"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.programVersion)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.programVersion)
            ? (_openBlock(), _createBlock(ProgramVersionDetailHeader, {
                key: 0,
                "program-version": _ctx.programVersion
              }, null, 8, ["program-version"]))
            : _createCommentVNode("", true),
          _createVNode(AvatarsActivity, {
            "users-activity": usersActivity.value,
            orientation: "left",
            class: "avatars"
          }, null, 8, ["users-activity"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(ProgramVersionTargetsCard, { "program-version-targets": targets.value }, null, 8, ["program-version-targets"]),
              _createVNode(ProgramVersionDesignsCard)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(ProgramVersionGoalDescriptionCard, {
                goal: _ctx.programVersion.goal,
                description: _ctx.programVersion.description
              }, null, 8, ["goal", "description"]),
              _createVNode(ProgramVersionApplicationsCard, { applications: applications.value }, null, 8, ["applications"]),
              _createVNode(ProgramVersionTeamCard, { "program-version-team": team.value }, null, 8, ["program-version-team"])
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})