import gql from 'graphql-tag';

export const ASSET_MATERIAL_UPDATE = gql`
    mutation assetMaterialUpdate($input: AssetMaterialUpdateMutationInput!) {
        assetMaterialUpdate (input: $input) {
            assetMaterial {
                id
            }
        }
    }`;

export const ASSET_PROCESSING_TECHNIQUE_UPDATE = gql`
    mutation assetProcessingTechniqueUpdate($input: AssetProcessingTechniqueUpdateMutationInput!) {
        assetProcessingTechniqueUpdate (input: $input) {
            assetProcessingTechnique {
                id
            }
        }
    }`;

export const ASSET_PROP_UPDATE = gql`
mutation assetPropUpdate($input: AssetPropUpdateMutationInput!) {
    assetPropUpdate (input: $input) {
        assetProp {
            id
        }
    }
}`;

export const ASSET_PROP_CREATE = gql`
mutation assetPropCreate($input: AssetPropCreateMutationInput!) {
    assetPropCreate (input: $input) {
        assetProp {
            id
        }
    }
}`;