import gql from "graphql-tag";

export const PROGRAM_VERSION_TARGETS_FORM = gql`
  query getPrgramVersionTargetsValues {
    targetCategoryTypes {
        edges {
            node {
                id
                name
            }
        }
    }
    props {
      edges {
        node {
          id
          name
          propCategory {
            id
            name
            propClass {
              id
              name
            }
          }
          baseValueType {
            id
            name
            symbol
            description
          }
          baseUnitType {
            id
            name
            abbreviation
          }
        }
      }
    }
    unitTypes(orderBy: "abbreviation") {
    edges {
      node {
        id
        name
        abbreviation
      }
    }
  }
  valueTypes {
    edges {
      node {
        id
        name
        symbol
        description
      }
    }
  }
  }

`;
