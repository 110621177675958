import { AssetPropInterface, rawAssetPropInterface, ValueTypeInterface, ValueUnitInterface } from "@/models/assetsComparisonTableInterfaces"
import { ref } from "vue";


export const parsePropValue = (value1:string, value2:string, valueType: ValueTypeInterface, valueUnit: ValueUnitInterface) => {
    const parsedValue = ref('');
    if (valueType) {
        if (valueType.name == 'Between') {
            parsedValue.value = valueUnit ? `${value1} - ${value2} ${valueUnit.abbreviation}` : `${value1} - ${value2}` 
        }
        else if (valueType.name == 	'Boolean') {
            parsedValue.value = value1 == 'true' ? 'Yes' : 'No'
        }
        else {
            parsedValue.value = valueUnit ? `${valueType.symbol} ${value1} ${valueUnit.abbreviation}` :  `${valueType.symbol} ${value1}`
        }
    }   
    return parsedValue.value
}


export const useConverterForProps = (assetsProps: rawAssetPropInterface[]): AssetPropInterface[] => {
    const listOfFormatedProperties = ref([]);
    assetsProps.map(prop => {
        const newProp = {
            id: prop.node.prop.id,
            name: prop.node.prop.name,
            category: prop.node.prop.propCategory.name,
            class: prop.node.prop.propCategory.propClass.name,
            extraInformation: prop.node.extraInformation,
            value: parsePropValue(prop.node.value1, prop.node.value2, prop.node.valueType, prop.node.valueUnit)
        }
        listOfFormatedProperties.value.push(newProp);
    });
    return listOfFormatedProperties.value
}