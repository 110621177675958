
import { TechnologyAssetInterface, TechnologyAssetAsTableRowInterface } from '@/models/technologyAssetsInterface';
import { useNodeContentToString } from './useNodeContent';
    
export const useAssetDataToTableRow = ( asset:TechnologyAssetInterface ):TechnologyAssetAsTableRowInterface => {
    const tableRowAsset:TechnologyAssetAsTableRowInterface  = {
        id: asset.id,
        nickname: asset.nickname,
        title: asset.title, 
        abstract: asset.abstract,
        description: asset.description,        
        assetType: formatAssetType(asset.assetType.toLowerCase()),
        sources: useNodeContentToString(asset?.sources.edges, 'name'),
        publicationDate: asset.publicationDate,
        url: asset.url ? `<p>${asset.url}</p>` : null,
        tags: asset.tags.join(', '),
        notes: asset.notes, 
        applicationTypes: useNodeContentToString(asset?.applicationTypes.edges, 'name'),
        materials: useNodeContentToString(asset?.materials.edges, 'name'),
        processingTechniques: useNodeContentToString(asset?.processingTechniques.edges, 'name'),
        standards: useNodeContentToString(asset?.standards.edges, 'code'), 
        owners: asset.assetType.toLowerCase() == 'other' ? useNodeContentToString(asset?.owners.edges, 'nickname') : null,
        files: asset.assetType.toLowerCase() == 'other' ? formatFiles(asset?.assetFiles.edges) : null
    };
    return tableRowAsset;
}

export const formatFiles = (fileList) => {
    if(fileList.length > 0) {
        let filesToReturn = '';
        fileList.map(fileNode => {
            const newLink = `<a class="block mb-2 text-sky-800 underline-offset-4 underline" target="_blank" href=${fileNode.node.file.url}>${fileNode.node.file.name.replace('assets/', '') }</a>`;
            filesToReturn += newLink;
        })
        return filesToReturn
    } else {
        return ''
    }
}

export const formatAssetType = (assetType:string) => {
    return assetType == 'research_project' ? 'External Opportunity'
            : assetType == 'other' ? 'Commercial & Other'
            : (assetType.charAt(0).toUpperCase() + assetType.slice(1)).replace('_', ' ');
}


export const parseAssetsResponse = ( assetsRawList ) => {
    const assetsToParse = assetsRawList.map(a => a.node);
    return assetsToParse.map(asset => useAssetDataToTableRow(asset));
}

export const parseAssetToExtendedContent = (asset )  => {
   const keesToKeep =  ['id', 'nickname', 'title', 'abstract', 'description', 'notes'];
   return keesToKeep.reduce((obj, key:string) => ({ ...obj, [key]: asset[key]}), {});
}

export const parseAssetToSummarizedContent = (asset )  => {
    const keesToKeep =  ['sources', 'publicationDate', 'applicationTypes', 'url', 'tags'];
    return keesToKeep.reduce((obj, key:string) => ({ ...obj, [key]: asset[key]}), {});
}
 
