import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pl-5 pt-8 pr-4 pb-8" }
const _hoisted_2 = { class: "pl-5 pt-8 pr-4 pb-8" }
const _hoisted_3 = ["disabled"]

import {computed,ref} from "vue";
import { useStore } from 'vuex';
import AIChatHistoryItem from "@/components/ai/AIChatHistoryItem.vue";
import { IChat } from "@/models/aiInterfaces";
import ModalSlots from "@/components/ModalSlots.vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatsHistory',
  props: {
    chatId: {
        type: String,
        required: false
    },
    chats:{
        type: Array as () => IChat[],
        required: true
    }
},
  setup(__props) {

const props = __props;


const store = useStore();
const router = useRouter();

const chatToDelete = ref(null);
const handleChatDelete = (chat) => {
    chatToDelete.value=chat;
    isDeleteOpen.value = true;
}
const isDeleteOpen = ref(false);

const deleteChat = async () => {
    const result = await  store.dispatch('aiChatModule/deleteChat', chatToDelete.value.id);
    if(result){
        isDeleteOpen.value = false;
        // controll if deleted chat is the current chat
        if(chatToDelete.value.id == props.chatId){
            router.push({name: 'AI'});
        }
    }
}

const chatToRename = ref(null);
const handleChatRename = (chat) => {
    chatToRename.value=chat;
    chatNewName.value = chat.name;
    isRenameOpen.value = true;
}
const isRenameOpen = ref(false);
const chatNewName = ref('');
const chatRenameIsValid = computed(()=> chatNewName.value.length > 0);
const renameChat = async () => {
    if(!chatRenameIsValid.value){
        return;
    }

    const result = await  store.dispatch('aiChatModule/renameChat', {chatId: chatToRename.value.id, name: chatNewName.value});
    if(result){
        isRenameOpen.value = false;
    }
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.chats, (chat) => {
      return (_openBlock(), _createElementBlock("div", {
        key: chat.id
      }, [
        _createVNode(AIChatHistoryItem, {
          chat: chat,
          class: _normalizeClass({'bg-gray-300': chat.id == __props.chatId}),
          onChatDelete: handleChatDelete,
          onChatRename: handleChatRename
        }, null, 8, ["chat", "class"])
      ]))
    }), 128)),
    _createVNode(ModalSlots, {
      isOpen: isDeleteOpen.value,
      onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (isDeleteOpen.value=false))
    }, {
      header: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("h2", { class: "text-lg font-bold" }, "Delete Chat ?", -1)
      ])),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createTextVNode(" This will delete ")),
          _createElementVNode("b", null, _toDisplayString(chatToDelete.value.name), 1)
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isDeleteOpen.value=false)),
          class: "button bg-white text-black"
        }, "Cancel"),
        _createElementVNode("button", {
          onClick: deleteChat,
          class: "button bg-red-500 text-white"
        }, "Delete")
      ]),
      _: 1
    }, 8, ["isOpen"]),
    (isRenameOpen.value)
      ? (_openBlock(), _createBlock(ModalSlots, {
          key: 0,
          isOpen: isRenameOpen.value,
          onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (isRenameOpen.value=false))
        }, {
          header: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("h2", { class: "text-lg font-bold" }, "Rename Chat", -1)
          ])),
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["m-2", { 'error-form-field': !chatRenameIsValid.value }])
              }, [
                _cache[8] || (_cache[8] = _createElementVNode("label", { class: "block" }, "New Name", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "w-full",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((chatNewName).value = $event)),
                  required: ""
                }, null, 512), [
                  [_vModelText, chatNewName.value]
                ])
              ], 2)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (isRenameOpen.value=false)),
              class: "button bg-white text-black"
            }, "Cancel"),
            _createElementVNode("button", {
              onClick: renameChat,
              class: _normalizeClass(["button green-accept text-white", { 'cursor-not-allowed hover:shadow-none opacity-50': !chatRenameIsValid.value }]),
              disabled: !chatRenameIsValid.value
            }, "Rename", 10, _hoisted_3)
          ]),
          _: 1
        }, 8, ["isOpen"]))
      : _createCommentVNode("", true)
  ]))
}
}

})