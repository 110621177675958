import gql from "graphql-tag";


export const AI_ASK = gql`
mutation($input:AskAIMutationInput!){
    askAi(input:$input){
        chatMessage{
            id
            messageType
            createdAt
            messageContents{
                edges{
                    node{
                        id
                        content
                        contentType
                    }
                }
            }
        }
    }
}
`;

export const CHAT_CREATE = gql`
mutation($input:ChatCreateMutationInput!){
    chatCreate(input:$input){
        chat{
            id
            name
            chatKey
        }
    }
}
`;

export const CHAT_DELETE = gql`
mutation($input:ChatDeleteMutationInput!){
    chatDelete(input:$input){
        chat{
            name
        }
    }
}
`;

export const CHAT_RENAME = gql`
mutation($input:ChatRenameMutationInput!){
    chatRename(input:$input){
        chat{
            id
            name
        }
    }
}
`;