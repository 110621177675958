import { ref } from "vue";
import { useNodeContentAsList, useNodeContentAsListWithConcat } from "./useNodeContentAsList";

export async function useAssetDataDetailToAssessmentContent(asset) {
  const assetDetailAssessmentData = ref(null);
  try {
    assetDetailAssessmentData.value = {
      id: asset?.id,
      highLevel: {
        files: asset?.assetFiles.edges,
        fields: [
          {
            title: "Status",
            content: asset?.assetStatus.name,
            mutationValue: "assetStatus",
            inputType: "select",
            queryName: "assetEvaluationStatuses",
            queryOptions: "assetEvaluationStatusesOptions",
          },
          {
            title: "Status Reason",
            content: asset?.statusReason ? asset?.statusReason : "",
            mutationValue: "statusReason",
            inputType: "textarea",
          },
          {
            title: "TRL",
            content: asset?.trl,
            mutationValue: "trl",
            inputType: "number",
          },
          // {
          //   title: "Tags",
          //   content: asset?.tags.length > 0 ? asset?.tags : null,
          //   mutationValue: "tags",
          //   inputType: "multipleSelect",
          //   queryName: "tags",
          //   queryOptions: "tagsOptions",
          // },
          {
            title: "Applications",
            content: useNodeContentAsList(
              asset?.applicationTypes.edges,
              "name"
            ),
            mutationValue: "applicationTypes",
            inputType: "multipleSelect",
            queryName: "applicationTypes",
            queryOptions: "applicationTypesOptions",
          },
          {
            title: "Materials",
            content: useNodeContentAsListWithConcat(asset?.materials.edges, "name", "acronym", true),
            mutationValue: "materials",
            inputType: "multipleSelect",
            queryName: "materials",
            queryOptions: "materialsOptions",
          },
          {
            title: "Certifications and standards",
            content: useNodeContentAsList(asset?.standards.edges, "code"),
            mutationValue: "standards",
            inputType: "multipleSelect",
            queryName: "standards",
            queryOptions: "standardsOptions",
          },
          {
            title: "Processing Techniques",
            content: useNodeContentAsList(
              asset?.processingTechniques.edges,
              "name"
            ),
            mutationValue: "processingTechniques",
            inputType: "multipleSelect",
            queryName: "processingTechniques",
            queryOptions: "processingTechniquesOptions",
          },
          {
            title: "Notes",
            content: asset?.notes ? asset?.notes : "",
            mutationValue: "notes",
            inputType: "textarea",
          },
        ],
      },
      programs: {
        programList: asset.assetLinkedPrograms.edges.map((edge) => edge.node),
      },
      maturity: {
        toBeDefined: "work in progress",
      },
      indepth: {
        toBeDefined: "work in progress",
      },
      test: {
        toBeDefined: "work in progress",
      },
    };
    if (asset.assetType == "OTHER") {
      const extrafieldsForOther = ref([
        {
          title: "Layer functionality",
          content: useNodeContentAsList(asset?.targetLayers.edges, "name"),
          mutationValue: "targetLayers",
          inputType: "multipleSelect",
          queryName: "layerTypes",
          queryOptions: "layerTypesOptions",
        },
        {
          title: "Form Type",
          content: useNodeContentAsList(asset?.formTypes.edges, "name"),
          mutationValue: "formTypes",
          inputType: "multipleSelect",
          queryName: "assetFormTypes",
          queryOptions: "assetFormTypesOptions",
        },
      ]);
      assetDetailAssessmentData.value.highLevel.fields =
        assetDetailAssessmentData.value.highLevel.fields.concat(
          extrafieldsForOther.value
        );
    }
  } catch {
    (error) => console.log(error);
  }
  return assetDetailAssessmentData;
}
