<template>
  <ModalSlots :show="isOpen" @close-modal="$emit('closeModal')"
    dialogClass="prototype-designer-modal">
    <template v-slot:header>
      <p></p>
    </template>
    <template v-slot:body>
      <div class="request-container">
        <TransitionRoot>
          <div v-if="loading" class="loader"><img src="../assets/icons/clip-load.gif" loading="lazy"/></div>
          <iframe class="airtable-embed" src="https://airtable.com/embed/appkETMHHt4xkfcSH/shrSBvmlj1LtqJEzM" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>     
        </TransitionRoot>
      </div>
    </template>
    <template v-slot:footer>   
          <div class="btn-modal">
            <button type="button" class="button cancel x-cancel" @click="$emit('closeModal')">
              Close
            </button>
          </div>
          </template>   
  </ModalSlots>
</template>

<script setup lang="ts">
import ModalSlots from "@/components/ModalSlots.vue";
import { defineProps, defineEmits, ref, onUpdated } from 'vue';
import { TransitionRoot } from '@headlessui/vue';

defineProps({ isOpen: Boolean });
defineEmits(['closeModal']);

const loading = ref(true);

onUpdated(() => {
    if (loading.value) {
        setTimeout(() => {
          loading.value = false
        }, 3000)
    } else {  setTimeout(() => {
      loading.value = true
        }, 800)}
})

</script>
<style lang="scss" scoped>
.request-container {
  height: 533px;
  width: auto;

  .btn-modal {
    padding: 15px;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 533px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>