<template>
    <div>
        <div v-for="chat in chats" :key="chat.id">
            <AIChatHistoryItem  :chat="chat" :class="{'bg-gray-300': chat.id == chatId}" @chatDelete="handleChatDelete" @chatRename="handleChatRename" />
        </div>
        <ModalSlots :isOpen="isDeleteOpen" @closeModal="isDeleteOpen=false">
            <template v-slot:header>
                <h2 class="text-lg font-bold">Delete Chat ?</h2>
            </template>
            <template v-slot:body>
                <div class="pl-5 pt-8 pr-4 pb-8">
                    This will delete <b>{{ chatToDelete.name }}</b>
                </div>
            </template>
            <template v-slot:footer>
                <button @click="isDeleteOpen=false" class="button bg-white text-black">Cancel</button>
                <button @click="deleteChat" class="button bg-red-500 text-white">Delete</button>
            </template>
        </ModalSlots>
        <ModalSlots v-if="isRenameOpen" :isOpen="isRenameOpen" @closeModal="isRenameOpen=false">
            <template v-slot:header>
                <h2 class="text-lg font-bold">Rename Chat</h2>
            </template>
            <template v-slot:body>
                <div class="pl-5 pt-8 pr-4 pb-8">
                    <div class="m-2" :class="{ 'error-form-field': !chatRenameIsValid }">
                        <label class="block">New Name</label>
                        <input type="text" class="w-full" v-model="chatNewName" required/>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <button @click="isRenameOpen=false" class="button bg-white text-black">Cancel</button>
                <button @click="renameChat"  class="button green-accept text-white" :disabled="!chatRenameIsValid" :class="{ 'cursor-not-allowed hover:shadow-none opacity-50': !chatRenameIsValid }">Rename</button>
            </template>
        </ModalSlots> 
    </div>
</template>
<script lang="ts" setup>
import {computed, defineProps,ref} from "vue";
import { useStore } from 'vuex';
import AIChatHistoryItem from "@/components/ai/AIChatHistoryItem.vue";
import { IChat } from "@/models/aiInterfaces";
import ModalSlots from "@/components/ModalSlots.vue";
import { useRouter } from "vue-router";

const props = defineProps({
    chatId: {
        type: String,
        required: false
    },
    chats:{
        type: Array as () => IChat[],
        required: true
    }
});


const store = useStore();
const router = useRouter();

const chatToDelete = ref(null);
const handleChatDelete = (chat) => {
    chatToDelete.value=chat;
    isDeleteOpen.value = true;
}
const isDeleteOpen = ref(false);

const deleteChat = async () => {
    const result = await  store.dispatch('aiChatModule/deleteChat', chatToDelete.value.id);
    if(result){
        isDeleteOpen.value = false;
        // controll if deleted chat is the current chat
        if(chatToDelete.value.id == props.chatId){
            router.push({name: 'AI'});
        }
    }
}

const chatToRename = ref(null);
const handleChatRename = (chat) => {
    chatToRename.value=chat;
    chatNewName.value = chat.name;
    isRenameOpen.value = true;
}
const isRenameOpen = ref(false);
const chatNewName = ref('');
const chatRenameIsValid = computed(()=> chatNewName.value.length > 0);
const renameChat = async () => {
    if(!chatRenameIsValid.value){
        return;
    }

    const result = await  store.dispatch('aiChatModule/renameChat', {chatId: chatToRename.value.id, name: chatNewName.value});
    if(result){
        isRenameOpen.value = false;
    }
}


</script>