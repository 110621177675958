<template>
   <div class="mb-8">
      <div class="mb-3">
         <label>Property *</label> 
         <DropdownSearchableSingle v-if="formValueOptions['propsOptions']" :options-list="formValueOptions['propsOptions']" v-model="prop" />
         <p v-if="propertyError" class="error-form-field"><label>{{ propertyError }}</label></p>
         
      </div>
      
      <label>Value Type</label>
      <select v-model="valueType" class="w-full mb-3">
         <option v-for="valueType in formValueOptions['valueTypesOptions']" :key="valueType.id" :value="valueType">{{ valueType.description }}</option>
      </select>

      <div class="mb-3" v-if="valueType && valueType.name == 'Boolean'">
         <label >Value</label>
         <div class="flex">
            <input class="shrink-0" type="checkbox" v-model="value1" true-value="true" false-value="false" />
            <label v-if="value1 && value1 == 'true'">Yes</label>
            <label v-else>No</label>
         </div>
      </div>
      <div v-else class="flex justify-between">
         <div :class="valueType && valueType.name == 'Between' ? 'w-2/5' : 'w-full'" class="mb-3">
            <label >Value</label>
            <input type="text" v-model="value1" class="w-full"/>
            <p v-if="valueError" class="error-form-field"><label>{{ valueError }}</label></p>
         </div>
         <div v-if="valueType && valueType.name == 'Between'" class="w-2/5"> 
            <label>Value 2</label>
            <input type="text" v-model="value2" class="w-full mb-3"/>
         </div>
      </div>

      <label>Unit</label>
      <select v-model="valueUnit" class="w-full mb-3">
         <option :value="null"></option>
         <option v-for="unitOption in formValueOptions['unitTypesOptions']" :key="unitOption.id" :value="unitOption.id">{{unitOption.abbreviation}} - {{ unitOption.name }}</option>
      </select>

      <div class="mb-3 assessment-editor">
         <label>Extra Information</label>
         <QuillEditor v-model:content="extraInformation" content-type="html" @ready="onEditorReady($event)" />
      </div>
      
      <div class="edit-assessment-buttons"> 
         <div @click="handleSaveChanges" class="on-green"><CheckIcon class="icon"/><p>Save Changes</p></div>
         <div @click="$emit('cancelAddition')"><XMarkIcon class="icon"/><p>cancel</p></div> 
      </div> 
   </div>
</template>
<script setup lang="ts">
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { AssetPropCreateMutationInput, PropWithRecommendationInterface } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { defineEmits, computed, ref, watch} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ASSET_PROPS_FORM_VALUES } from '@/grapql/assetPropsFormQueries';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';



const emit = defineEmits(['cancelAddition'])

const store = useStore();
const onEditorReady = (event: Event) => {return event};
const erroFromMutation = computed(() => store.state.assetDetailModule.errorsDuringMutation.assetPropCreate)
const assetId = computed(() => store.getters['assetDetailModule/getAssetId']);

const { result } = useQuery(ASSET_PROPS_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const formValueOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes', 'valueTypes', 'props']));

const propsWithRecomendation = computed<PropWithRecommendationInterface[]>(() => inizilizedData.value && inizilizedData.value.props ? inizilizedData.value?.props.edges.map(edge => edge.node) : []);

const formSubmited = ref(false);
const valueError = computed(() => formSubmited.value && !value1.value ?  "Please assign a value to the property" : null);
const propertyError = computed(() => formSubmited.value && !prop.value ? "Pleasee select a property" : null);

const prop = ref<number>(null);
const valueType = ref<DropdownSearchableOptionInterface>(null);
const value1 = ref<string>(null);
const value2 = ref<string>(null);
const valueUnit = ref<number>(null);
const extraInformation = ref<string>(null);

watch(() => prop.value, () => {
	if(prop.value) {
		const propSelected = propsWithRecomendation.value.find(property => property.id == prop.value);
      if (propSelected && propSelected.baseValueType) {
         valueType.value =  formValueOptions.value['valueTypesOptions'].find(valueType => valueType.id == propSelected.baseValueType.id);
      }
      if (propSelected && propSelected.baseUnitType) { valueUnit.value = propSelected.baseUnitType.id;}
   }
})

const createMutationInput = () => {
   const createPropInput = ref<AssetPropCreateMutationInput>({
      asset: assetId.value, 
      prop: Number(prop.value),
      value1: value1.value,
      value2: value2.value,
      valueType: valueType.value ? valueType.value.id : null,
      valueUnit: valueUnit.value,
      extraInformation: extraInformation.value,
   });
   
   return createPropInput;
}

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!propertyError.value && !valueError.value) {
     const mutationInput = createMutationInput();
     await store.dispatch('assetDetailModule/assetPropCreate', mutationInput.value)
      .then(() => { 
         if (!erroFromMutation.value) {
            emit('cancelAddition')
         }
      })
   }
}


</script>
<style lang="scss" scoped>
   .edit-assessment-buttons {
      margin: 0;
      margin-bottom: 1rem;
   }
   label {
		font-size: 0.9rem;
		color: $font-color-super-light;
		font-weight: 300;
	}

	.value {
		font-size: 0.9rem;
		color: rgb(109, 109, 109);
		font-weight: 500;
		padding-bottom: 0.5rem;
		overflow: auto;
		text-transform: uppercase;
		overflow: visible;
	}
   .error-form-field label {
      font-size: 0.8rem;
      font-weight: 400;
      margin-top: 10px;
   }
</style>