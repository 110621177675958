<template>
    <div class="grid grid-cols-2 grid-flow-row gap-4">
        <div class="grid grid-flow-row gap-4">
            <div class="bg-white border p-5 rounded-md min-h-10">
                <p class="uppercase font-medium text-sm mb-3">Objetive</p>
                <p class="text-sm text-neutral-500 font-light" v-html="productBrief.objective"></p>
            </div>
            <div class="bg-white border p-5 rounded-md">
                <ProductBriefClientCard :product-brief-client="productBrief.client" />
            </div>
            <div class="bg-white border p-5 rounded-md min-h-40">
                <p class="uppercase font-medium text-sm mb-3">Files</p>
            </div>
        </div>
        <div class="grid grid-flow-row gap-4">
            <ProductBriefMarketsCard :markets="productBrief.markets" />
            
            <div class="bg-white border p-5 rounded-md min-h-40">
                <p class="uppercase font-medium text-sm mb-3">Targets</p>
                <MaterialTargetTable :target-data="productBriefTargets" :is-product-brief="true"/>
            </div>
            <div class="bg-white border p-5 rounded-md min-h-48">
                <p class="uppercase font-medium text-sm mb-3">Materials</p>
            <MaterialTargetTable :material-data="productBriefMaterials" :is-product-brief="true"/> 
            </div>
        </div>
        
    </div>
</template>
<script setup lang="ts">
import { defineProps, computed } from 'vue';
import ProductBriefClientCard from '@/components/ProductBriefClientCard.vue';
import ProductBriefMarketsCard from '@/components/ProductBriefMarketsCard.vue';
import { MaterialInterface, ProductBriefInterface } from '@/models/productBriefsInterface';
import MaterialTargetTable from './MaterialTargetTable.vue';
import { TargetInterface } from '@/models/programVersionsInterface';

const props = defineProps<{
    productBrief: ProductBriefInterface
}>();

const productBriefAllTargets = computed<TargetInterface[]>(() => props.productBrief.targets.edges.map((edge) => edge.node) ?? null);
const productBriefTargets = computed<TargetInterface[]>(() => productBriefAllTargets.value.filter(target => !!target.targetProp));
const productBriefMaterials = computed<MaterialInterface[]>(() => props.productBrief.materials.edges.map((edge) => edge.node) ?? null);

</script>
<style lang="scss">
.position-x-center {
    left: 50%;
    transform: translate(-50%, 0);
}
</style>