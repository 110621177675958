<template>
      <div class="flex items-start space-x-4 mb-12 relative">
        <div class="w-10 flex-shrink-0">
            <div class="flex items-center justify-center border border-gray-200 rounded-full h-10 w-10">
                <CpuChipIcon class="icon icon-small black" @click="toggle()"/>
            </div>
        </div>
        
        <div class="flex-1 overflow-x-auto ml-4 p-3 border border-gray-200">
            <div v-if="isClosed" class="text-gray-400 cursor-pointer " @click="toggle()">
                : : : : : : : : : :
            </div>
            <div v-else>
              <template v-for="(content, index) in messageContents" :key="index">
                  <AIChatGroupedSandwichesRenderer v-if="content.contentType === ContentType.SANDWICH" :contents="content.content"/>
                  <span v-else v-html="content.content" class="whitespace-pre-line"></span>
              </template> 
              <HeartBeat v-if="showHearBeating" style="display:inline-block !important;"/>
            </div>
        </div>
        <div v-if="!isSubscriptionActive" class="absolute top-full left-10 pr-3 py-1">
            <HandThumbUpIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
            <ArrowPathIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
            <HandThumbDownIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, h, ref, watch, onMounted,PropType} from 'vue';
import { CpuChipIcon, HandThumbDownIcon, HandThumbUpIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import {ContentType} from '@/models/aiModels';
import AIChatGroupedSandwichesRenderer from './AIChatGroupedSandwichesRenderer.vue';
import { renderToString } from '@vue/server-renderer';
import HeartBeat from './HeartBeat.vue';
import {IChatMessage} from '@/models/aiInterfaces';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
  });

const store = useStore();

// get last messageId from store
const lastMesssageId = computed(()=> store.getters["aiChatModule/getLastMessageId"]);

const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

const showHearBeating = computed(()=> lastMesssageId.value === props.message.id && isSubscriptionActive.value);

// handle collapse and expand of the answer container
const isClosed = ref(false);
const toggle = () => {
    isClosed.value = !isClosed.value;
}

const messageContents = computed(()=>{
  // Object to hold grouped SANDWICH items by groupId
  const sandwichGroups = {};

  // First, iterate over messageContents and group SANDWICH items
  for (const item of props.message.messageContents) {
    if (item.contentType === ContentType.SANDWICH) {
      let parsedContent = JSON.parse(item.content);
      // Ensure groupId exists inside the parsed content
      if (!parsedContent.groupId) {
        parsedContent.groupId = uuidv4();  // Assign a new UUID
        item.content = JSON.stringify(parsedContent);  // Update content with the new groupId
      }

      // Add item to its group
      if (!sandwichGroups[parsedContent.groupId]) {
        sandwichGroups[parsedContent.groupId] = [];
      }
      sandwichGroups[parsedContent.groupId].push(parsedContent);
    }
  }

  const contents=  props.message.messageContents.map((item) => {
    const parsedContent = JSON.parse(item.content);

    if (item.contentType === ContentType.TABLE) {
      return {"contentType":ContentType.TABLE,"content":`<div class="table-container">${parsedContent}</div>`};
    }
    else if(item.contentType === ContentType.SANDWICH) {
      const {groupId} = parsedContent;
      // Render the group only once
      if (sandwichGroups[groupId]) {
        const sandwiches = sandwichGroups[groupId];
        // Remove the group so it doesn't get rendered again
        delete sandwichGroups[groupId];

        return {"contentType":ContentType.SANDWICH,"content":sandwiches}
      }
      // Return null when no sandwiches are found
      return null; 
    }else{
      return {"contentType":ContentType.TEXT,"content":`<span>${parsedContent}</span>`};
    }
  });

  return contents.filter(item => item !== null);

});

// const html = ref<string>('');
// old_before_grouping
// async function updateHtml(){
//     html.value = '';
//     const newHtmlTexts = await Promise.all(props.message.messageContents.map(async (item) => {
//     const parsedContent = JSON.parse(item.content);

//     if (item.contentType === ContentType.TABLE) {
//       return `<div class="table-container">${parsedContent}</div>`;
//     }

//     if (item.contentType === ContentType.SANDWICH) {
//       return await renderToString(h(AIChatSandwichRenderer,{content:parsedContent}))
//     }
    
//     return `<span>${parsedContent}</span>`;
//   }));

//   html.value = newHtmlTexts.join(' ');
// }
// Watch for changes to props.answer and update HTML
// watch(() => props.message, () => {
//   updateHtml();
// }, { deep: true });

// // Ensure updateHtml runs on component mount
// onMounted(() => {
//   updateHtml();
// });
</script>