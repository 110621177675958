import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-start space-x-4 mb-12 relative" }
const _hoisted_2 = { class: "w-10 flex-shrink-0" }
const _hoisted_3 = { class: "flex items-center justify-center border border-gray-200 rounded-full h-10 w-10" }
const _hoisted_4 = { class: "flex-1 overflow-x-auto ml-4 p-3 border border-gray-200" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "absolute top-full left-10 pr-3 py-1"
}

import { computed, h, ref, watch, onMounted,PropType} from 'vue';
import { CpuChipIcon, HandThumbDownIcon, HandThumbUpIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import {ContentType} from '@/models/aiModels';
import AIChatGroupedSandwichesRenderer from './AIChatGroupedSandwichesRenderer.vue';
import { renderToString } from '@vue/server-renderer';
import HeartBeat from './HeartBeat.vue';
import {IChatMessage} from '@/models/aiInterfaces';
import { v4 as uuidv4 } from 'uuid';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatAnswer',
  props: {
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
  },
  setup(__props) {

const props = __props;

const store = useStore();

// get last messageId from store
const lastMesssageId = computed(()=> store.getters["aiChatModule/getLastMessageId"]);

const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

const showHearBeating = computed(()=> lastMesssageId.value === props.message.id && isSubscriptionActive.value);

// handle collapse and expand of the answer container
const isClosed = ref(false);
const toggle = () => {
    isClosed.value = !isClosed.value;
}

const messageContents = computed(()=>{
  // Object to hold grouped SANDWICH items by groupId
  const sandwichGroups = {};

  // First, iterate over messageContents and group SANDWICH items
  for (const item of props.message.messageContents) {
    if (item.contentType === ContentType.SANDWICH) {
      let parsedContent = JSON.parse(item.content);
      // Ensure groupId exists inside the parsed content
      if (!parsedContent.groupId) {
        parsedContent.groupId = uuidv4();  // Assign a new UUID
        item.content = JSON.stringify(parsedContent);  // Update content with the new groupId
      }

      // Add item to its group
      if (!sandwichGroups[parsedContent.groupId]) {
        sandwichGroups[parsedContent.groupId] = [];
      }
      sandwichGroups[parsedContent.groupId].push(parsedContent);
    }
  }

  const contents=  props.message.messageContents.map((item) => {
    const parsedContent = JSON.parse(item.content);

    if (item.contentType === ContentType.TABLE) {
      return {"contentType":ContentType.TABLE,"content":`<div class="table-container">${parsedContent}</div>`};
    }
    else if(item.contentType === ContentType.SANDWICH) {
      const {groupId} = parsedContent;
      // Render the group only once
      if (sandwichGroups[groupId]) {
        const sandwiches = sandwichGroups[groupId];
        // Remove the group so it doesn't get rendered again
        delete sandwichGroups[groupId];

        return {"contentType":ContentType.SANDWICH,"content":sandwiches}
      }
      // Return null when no sandwiches are found
      return null; 
    }else{
      return {"contentType":ContentType.TEXT,"content":`<span>${parsedContent}</span>`};
    }
  });

  return contents.filter(item => item !== null);

});

// const html = ref<string>('');
// old_before_grouping
// async function updateHtml(){
//     html.value = '';
//     const newHtmlTexts = await Promise.all(props.message.messageContents.map(async (item) => {
//     const parsedContent = JSON.parse(item.content);

//     if (item.contentType === ContentType.TABLE) {
//       return `<div class="table-container">${parsedContent}</div>`;
//     }

//     if (item.contentType === ContentType.SANDWICH) {
//       return await renderToString(h(AIChatSandwichRenderer,{content:parsedContent}))
//     }
    
//     return `<span>${parsedContent}</span>`;
//   }));

//   html.value = newHtmlTexts.join(' ');
// }
// Watch for changes to props.answer and update HTML
// watch(() => props.message, () => {
//   updateHtml();
// }, { deep: true });

// // Ensure updateHtml runs on component mount
// onMounted(() => {
//   updateHtml();
// });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(CpuChipIcon), {
          class: "icon icon-small black",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (isClosed.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text-gray-400 cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (toggle()))
          }, " : : : : : : : : : : "))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messageContents.value, (content, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (content.contentType === _unref(ContentType).SANDWICH)
                  ? (_openBlock(), _createBlock(AIChatGroupedSandwichesRenderer, {
                      key: 0,
                      contents: content.content
                    }, null, 8, ["contents"]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      innerHTML: content.content,
                      class: "whitespace-pre-line"
                    }, null, 8, _hoisted_6))
              ], 64))
            }), 128)),
            (showHearBeating.value)
              ? (_openBlock(), _createBlock(HeartBeat, {
                  key: 0,
                  style: {"display":"inline-block !important"}
                }))
              : _createCommentVNode("", true)
          ]))
    ]),
    (!isSubscriptionActive.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_unref(HandThumbUpIcon), { class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black" }),
          _createVNode(_unref(ArrowPathIcon), { class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black" }),
          _createVNode(_unref(HandThumbDownIcon), { class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})