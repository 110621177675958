import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap bg-white text-sm table-container"
}
const _hoisted_2 = { class: "flex items-strech sticky top-0 z-30" }
const _hoisted_3 = { class: "uppercase font-normal" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "p-2 w-72 border-r bg-white sticky left-0 z-20" }
const _hoisted_6 = { class: "uppercase text-charcoal" }
const _hoisted_7 = { class: "text-xs text-zinc-400 font-light capitalize" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "w-56 border-r text-charcoal text-center p-2 z-10 flex flex-col items-center justify-center"
}
const _hoisted_10 = { class: "text-charcoal font-semibold" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "w-56 border-r text-center p-2 text-charcoal z-10 flex items-center justify-center"
}
const _hoisted_13 = {
  key: 1,
  class: "wip-page"
}

import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ASSET_DATA_FOR_COMPARISON_TABLE } from '@/grapql/assetsPropertiesForComparisonTable';
import { AssetComparisonTableDataInterface, PropInterface, rawAssetDataInterface } from '@/models/assetsComparisonTableInterfaces';
import {useConverterForProps} from '@/composables/useConverterForPropValue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsComparisonTable',
  setup(__props) {

const store = useStore();

// Get values from store
const assetsIdToCompareFromStore = computed(() => store.state.assetsModule.assetsToCompare.length > 0 ? store.state.assetsModule.assetsToCompare.map(asset => asset.id) : []);
const propertiesToCompareFromStore = computed(() => store.getters['assetsModule/getComparisonProperties']);


// Assign values from store to a reactive and modifiable variable
const assetsIdsToCompare = ref(assetsIdToCompareFromStore.value);
const propertiesToCompare = ref<PropInterface[]>(propertiesToCompareFromStore.value);

const assetsToCompare = ref<AssetComparisonTableDataInterface[]>(null);


// Watch for changes in the store 
watch([propertiesToCompareFromStore, assetsIdToCompareFromStore], ([newpropertiesToCompareFromStore, newassetsIdToCompareFromStore]) => {
    propertiesToCompare.value = newpropertiesToCompareFromStore;
    if (newassetsIdToCompareFromStore) {
        assetsIdsToCompare.value = newassetsIdToCompareFromStore;
        getAssetsData(); 
    }
})


const useAssetDataToCompareTable = (assetData: rawAssetDataInterface) => {
    const parsedData = ref<AssetComparisonTableDataInterface>({
        id: assetData.id,
        nickname: assetData.nickname,
        title: assetData.title,
        assetProps: assetData.assetProps.edges.length > 0 ? useConverterForProps(assetData.assetProps.edges) : []
    })
    return parsedData.value
}

const getAssetsData = () => {
    const assetsData = ref([]);
    assetsIdsToCompare.value.map( assetId => {
        const { onResult } = useQuery(ASSET_DATA_FOR_COMPARISON_TABLE, {id: assetId});
        onResult(queryResult => {
           if (queryResult.data) {
            const parsedData = useAssetDataToCompareTable(queryResult.data.asset);
            assetsData.value.push(parsedData);
            }
        })
        
    })
    assetsToCompare.value = assetsData.value
}

getAssetsData();   



return (_ctx: any,_cache: any) => {
  return (assetsToCompare.value && !!propertiesToCompare.value.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-72 bg-grey-light bg-charcoal sticky left-0 z-40 h-20" }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetsToCompare.value, (asset) => {
            return (_openBlock(), _createElementBlock("div", {
              key: asset.id,
              class: "bg-charcoal text-white w-56 border-r p-4 flex flex-col items-center sticky top-0 h-20"
            }, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(asset.nickname), 1),
              _createElementVNode("p", {
                class: "text-xs font-light text-center text-ellipsis overflow-hidden",
                innerHTML: asset.title
              }, null, 8, _hoisted_4)
            ]))
          }), 128))
        ]),
        (!!propertiesToCompare.value.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex w-full uppercase text-white bg-danger-light py-1 px-3 text-sm font-medium sticky top-20 left-0 z-30" }, "Properties", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(propertiesToCompare.value, (property) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: property.id,
                  class: "flex divide-y divide-slate-200"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(property.name), 1),
                    _createElementVNode("p", _hoisted_7, [
                      _createTextVNode(_toDisplayString(property.propCategory.name) + " ", 1),
                      (property.propCategory.propClass.name != 'Unknow')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "/ " + _toDisplayString(property.propCategory.propClass.name), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetsToCompare.value, (asset) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: asset.id
                    }, [
                      (asset.assetProps.some(prop => prop.id == property.id))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("p", _hoisted_10, _toDisplayString(asset.assetProps.find(prop => prop.id == property.id).value), 1),
                            (asset.assetProps.find(prop => prop.id == property.id).extraInformation)
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 0,
                                  innerHTML: asset.assetProps.find(prop => prop.id == property.id).extraInformation,
                                  class: "text-xs text-zinc-400 font-light capitalize"
                                }, null, 8, _hoisted_11))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
                    ], 64))
                  }), 128))
                ]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[2] || (_cache[2] = [
        _createElementVNode("p", null, "comparison table", -1),
        _createElementVNode("p", { class: "wip-text" }, "No assets or criteria selection", -1)
      ])))
}
}

})