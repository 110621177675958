import gql from "graphql-tag";

export const PROGRAM_VERSION_QUERY = gql`
    query getProgramVersion($id: ID!) {
    programVersion(id: $id) {
        id
        goal
        name
        description
        version
        updatedBy {
            id
            lastName
            firstName
            username
        }
        updatedAt
        createdBy {
            id
            firstName
            lastName
            username
        }
        creationDate
        applicationTypes {
            edges {
                node {
                    id
                    name
                    className
                }
            }
        }
        program {
            id
            name
        }
        team {
            edges {
                node {
                    id
                    teamRoleType {
                        id
                        name
                        category
                    }
                    user {
                        firstName
                        id
                        lastName
                        username
                    }
                }
            }
        }
        targets {
            edges {
                node {
                    id
                    currentValue
                    targetCategoryType {
                        id
                        name
                    }
                    targetProp {
                        id
                        name
                        propCategory {
                        id
                        name
                        propClass {
                            id
                            name
                        }
                        }
                    }
                    targetValue1
                    targetValue2
                    targetValueType {
                        id
                        name
                        symbol
                        description
                    }
                    targetValueUnit {
                        abbreviation
                        id
                        name
                    }
                }
            }
        } 
        designs {
            edges {
                node {
                id
                nickname
                description
                status {
                    id
                    name
                    colorCode
                }
                statusReason
                layers {
                    edges {
                        node {
                            id
                            description
                            thicknessValue1
                            thicknessValue2
                            visualThickness
                            colorCode
                            layerOrder
                            layerType {
                                id
                                name
                                description
                            }
                            thicknessValueType {
                                id
                                symbol
                                name
                                description
                            }
                            thicknessUnitType {
                                abbreviation
                                id
                                name
                            }
                            asset {
                                id
                                nickname
                                assetType
                            }
                        }
                    }
                }
          
            }
        }
    }
}

}`;
