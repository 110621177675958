import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pt-4 pb-6 mx-3"
}
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = {
  key: 0,
  class: "w-1/5 table-title text-left ml-5"
}
const _hoisted_4 = { class: "flex items-center pt-2 pb-1 leading-4" }
const _hoisted_5 = { class: "w-3/5 table-title" }
const _hoisted_6 = { class: "target-info" }
const _hoisted_7 = { class: "target-info" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "value"
}
const _hoisted_10 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_11 = {
  key: 0,
  class: "value"
}
const _hoisted_12 = {
  key: 1,
  class: "value"
}
const _hoisted_13 = {
  key: 2,
  class: "value"
}
const _hoisted_14 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_15 = {
  key: 0,
  class: "w-1/5 font-medium ml-5 text-left"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "value-tbd"
}
const _hoisted_18 = {
  key: 1,
  class: "empty-box no-value"
}

import { computed } from 'vue';
import { TargetInterface } from '@/models/programVersionsInterface';
import { MaterialInterface } from '@/models/productBriefsInterface';
import { useStore } from 'vuex';


export interface TableDataInterface {
    id: number,
    property?: {
        id: number,
        name: string,
        className: string,
        category: string,
    },
    prop?: {
        id: number, 
        name: string, 
        propCategory: {
          id: number, 
          name: string, 
          propClass: {
            id: number, 
            name: string
          }
        } | null
    },
    value1: string,
    value2?: string | null
    valueType: {
        id: number,
        name: string,
        symbol: string,
        description: string,
    },
    valueUnit: {
        id: number,
        abbreviation: string,
        name: string
    }
    currentValue?: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialTargetTable',
  props: {
    materialData: {},
    targetData: {},
    isProductBrief: { type: Boolean }
  },
  setup(__props: any) {


const props = __props

const store = useStore();
const dataToShow = computed<TableDataInterface[]>(() => props.materialData ? parsedMaterialData.value : props.targetData ? parsedTargetData.value : []);

const parsedMaterialData = computed(() => props.materialData && props.materialData.length > 0 ? props.materialData.map(({ material, ...rest }) => ({ property: material, ...rest })) : [])

const parsedTargetData = computed(() => props.targetData && props.targetData.length > 0 ? props.targetData.map(({ targetProp, targetValue1, targetValue2, targetValueType, targetValueUnit, ...rest }) => ({
    prop: targetProp,
    value1: targetValue1,
    value2: targetValue2,
    valueType: targetValueType,
    valueUnit: targetValueUnit,
    ...rest
})) : [])

const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (dataToShow.value && dataToShow.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-3/5" }, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-1/5 table-title text-right mr-5" }, "Target", -1)),
            (isUserAllowToSeeExtraInfo.value && !_ctx.isProductBrief)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Current"))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataToShow.value, (dataValue) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "flex flex-col border-b last:border-0",
              key: dataValue.id
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (dataValue.property)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(dataValue.property.name) + " ", 1),
                        _createElementVNode("p", _hoisted_6, _toDisplayString(dataValue.property.className) + " / " + _toDisplayString(dataValue.property.category), 1)
                      ], 64))
                    : (dataValue.prop)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(dataValue.prop.name) + " ", 1),
                          _createElementVNode("p", _hoisted_7, [
                            _createTextVNode(_toDisplayString(dataValue.prop.propCategory.name) + " ", 1),
                            (dataValue.prop.propCategory.propClass.name != 'Unknown')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, "/ " + _toDisplayString(dataValue.prop.propCategory.propClass.name), 1))
                              : _createCommentVNode("", true)
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass([isUserAllowToSeeExtraInfo.value && !_ctx.isProductBrief ? 'w-1/5' : 'w-2/5', "text-right mr-5 font-medium"])
                }, [
                  (dataValue.valueType.name == 'Between')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                        _createTextVNode(_toDisplayString(dataValue.value1) + " - " + _toDisplayString(dataValue.value2) + " ", 1),
                        (dataValue.valueUnit)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(dataValue.valueUnit.abbreviation), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : (dataValue.valueType.name == 'Boolean')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (dataValue.value1 && dataValue.value1 == 'true')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_11, "Yes"))
                            : (_openBlock(), _createElementBlock("p", _hoisted_12, "No"))
                        ], 64))
                      : (_openBlock(), _createElementBlock("p", _hoisted_13, [
                          _createTextVNode(_toDisplayString(dataValue.valueType.symbol) + " " + _toDisplayString(dataValue.value1) + " ", 1),
                          (dataValue.valueUnit)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(dataValue.valueUnit.abbreviation), 1))
                            : _createCommentVNode("", true)
                        ]))
                ], 2),
                (isUserAllowToSeeExtraInfo.value && _ctx.targetData && !_ctx.isProductBrief)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      (dataValue.currentValue !== null)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(dataValue.currentValue), 1))
                        : (_openBlock(), _createElementBlock("p", _hoisted_17, "TBD"))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_18))
  ]))
}
}

})