<template>
	<Disclosure v-slot="{ open }" :defaultOpen="true">
		<DisclosureButton class="flex w-full py-2 text-left text-sm font-medium disclosure-button">
			<ChevronRightIcon :class="open ? 'chevron-rotate' : ''" class="h-5 w-5" />
			<p class="section-title">Properties</p>
		</DisclosureButton>

		<!-- Button and form to add new property -->
		<div class="add-on-disclosure" >
			<PlusCircleIcon class="icon" @click="onAddition = true" />
		</div>
		<AssetPropertyAddForm v-if="onAddition" @cancel-addition="onAddition = false" />

		<!-- Props values in the asset -->
		<DisclosurePanel class="pl-4">
			<div v-if="!!propsData.length" class="divide-y prop-info">
				<template v-for="prop in propsData" :key="prop.id">
					<AssetProperty :asset-prop="prop" />
				</template>
			</div>
			<p v-else class="mb-4 text-sm italic text-neutral-400">
				No properties assigned to this technology yet 
			</p>
			
		</DisclosurePanel>
	</Disclosure>
</template>
<script setup lang="ts">
import { AssetPropInterface } from '@/models/assetAssessmentInterfaces';
import { defineProps, ref } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import AssetProperty from './AssetProperty.vue';
import AssetPropertyAddForm from './AssetPropertyAddForm.vue';


defineProps<{
	propsData: AssetPropInterface[];
}>()

const onAddition = ref(false);


</script>