<template>
    <div>
        <div class="grid grid-cols-1 gap-4">
            <div>
                <div v-for="(chatMessage,index) in chatMessages" :key="index" class="chat-message">
                    <AIChatQuestion v-if="chatMessage.messageType == MessageType.QUESTION" :message="chatMessage" :chatKey="chatKey" />
                    <AIChatAnswer v-else-if="chatMessage.messageType == MessageType.ANSWER" :message="chatMessage"/>
                    <AIChatError v-else-if="chatMessage.messageType == MessageType.ERROR" :message="chatMessage"/>
                </div>
            </div>
        </div>  
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps,onMounted,PropType,watch } from 'vue';
import { useStore } from 'vuex';
import {useQuery} from '@vue/apollo-composable';
import {CHAT_MESSAGES} from "@/grapql/aiQuery";
import { useEdgesToChatMessages } from '@/composables/aiComposables';
import {  MessageType, ChatKey } from '@/models/aiModels';
import AIChatQuestion from "@/components/ai/AIChatQuestion.vue";
import AIChatAnswer from "@/components/ai/AIChatAnswer.vue";
import AIChatError from "@/components/ai/AIChatError.vue";
import { IChatMessage } from '@/models/aiInterfaces';
import {useRouter} from 'vue-router';


const props = defineProps({
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
});
const store = useStore();
const router = useRouter();

const chatMessages= computed<IChatMessage[]>(()=> store.getters['aiChatModule/getChatMessages']);

// load chat messages history
const fetchChatMessages = (chatId: string) => {
    const {onResult , onError} = useQuery(CHAT_MESSAGES,{chatId},{"fetchPolicy":"cache-and-network"});
    
    onResult(async queryResult => {
        // check if chatId exists
        if (queryResult.data && !queryResult?.data?.chat)
        {
            router.push({name: 'AI'});
        }
        else{
            if (queryResult.data && queryResult?.data?.chat?.messages) {
                const messages = useEdgesToChatMessages(chatId, queryResult.data.chat.messages.edges);
                if(messages.length > 0){
                    store.commit('aiChatModule/setChatMessages', messages);
                }
            }
        }
        
    });

    onError((error) => {
        console.log(error);
    });
};

watch(() => props.chatId, (newChatId) => {
    store.commit('aiChatModule/setChatMessages', []);
    fetchChatMessages(newChatId);
});

onMounted(() => {
    fetchChatMessages(props.chatId);
});

</script>