<template>
   <template v-if="!onEdit">
      <div class="w-full mt-3">
         <!-- Edition button -->
         <div class="flex justify-end w-full mt-3">
            <PencilSquareIcon class="icon" @click="onEdit = true" v-if="!onEdit"/>
         </div>
         <!-- Property class and category -->
         <div class="ml-1flex text-xs text-super-light uppercase mb-1">
            <span v-if="assetProp.prop.propCategory.name">{{assetProp.prop.propCategory.name }}</span>
            <span v-if="assetProp.prop.propCategory.propClass.name && assetProp.prop.propCategory.propClass.name != 'Unknown'"> - {{assetProp.prop.propCategory.propClass.name }}</span>
         </div>
         <!-- Property name, category and class -->
         <div class="flex items-center justify-between">
            <p class="name mb-3">{{ assetProp.prop.name }}</p>
            <!-- Different displays based on unit type -->
            <template v-if="assetProp.valueType">
               <p v-if="assetProp.valueType.name == 'Between'" class="value">
                  {{ assetProp.value1 }} - {{ assetProp.value2 }}
                  <span v-if="assetProp.valueUnit" class="lowercase">{{assetProp.valueUnit.abbreviation }}</span>
               </p>
               <template v-else-if="assetProp.valueType.name == 'Boolean'">
                  <p v-if="assetProp.value1 && assetProp.value1 == 'true'" class="value">Yes</p>
                  <p v-else class="value">No</p>
               </template>
               <p v-else class="value">
                  {{ assetProp.valueType.symbol }} {{ assetProp.value1 }}
                  <span v-if="assetProp.valueUnit" class="lowercase">{{
                     assetProp.valueUnit.abbreviation }}</span>
               </p>
            </template>
            <p v-else class="value">
               {{ assetProp.value1 }}
               <span v-if="assetProp.valueUnit" class="lowercase">{{
                  assetProp.valueUnit.abbreviation }}</span>
            </p>
         </div>
         <div class="w-full" v-if="assetProp.extraInformation">
            <p class="text-xs text-super-light">Extra Information</p>
            <p class="value extra-info" v-html=" assetProp.extraInformation"></p>
         </div>  
      </div>
   </template>
   <template v-else>
      <AssetPropertyEditForm :asset-prop="assetProp" @cancel-edition="onEdit = false"/>
   </template>
   
</template>
<script setup lang="ts">
import { AssetPropInterface } from '@/models/assetAssessmentInterfaces';
import { defineProps, ref } from 'vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetPropertyEditForm from './AssetPropertyEditForm.vue';


defineProps<{ 
   assetProp: AssetPropInterface; 
}>();

const onEdit = ref(null);

</script>
