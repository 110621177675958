<template>
    <!-- Mockup section -->
    <template v-if="programDetailInfoMockup">
        <TitleGoBackHeader :title="programDetailInfoMockup.version" />
        <AvatarsActivity :users-activity="programDetailInfoMockup.leadTeam" orientation="left" class="avatars" />
        <div class="program-detail-content">
            <div class="main-cards">
                <ProgramVersionTargetsCard :program-targets="programDetailInfoMockup.targets"
                    :product-brief="programDetailInfoMockup.productBrief" />
                <ProgramLayersDesign :layers-info="programDetailInfoMockup.layersInfo"
                    :alternative-designs="programDetailInfoMockup.prototypesDesignsInfo"
                    :program-targets="programDetailInfoMockup.targets" />
                <!-- <ProgramTechnologiesCard :layers-info="programDetailInfoMockup.layersInfo" /> -->
            </div>
            <div class="side-cards">
                <ProgramApplicationCard :description="programDetailInfoMockup.description"
                    :application="programDetailInfoMockup.application" :product-img="programDetailInfoMockup.productImage" />
                <ProgramTechniquesCard :production-techniques="programDetailInfoMockup.productionTechniques" />
            </div>
        </div>
    </template>
    <!-- Real section -->
    <template v-if="programVersion">
        <ProgramVersionDetailHeader v-if="programVersion" :program-version="programVersion" />
        <AvatarsActivity :users-activity="usersActivity" orientation="left" class="avatars" />
        <div class="program-detail-content">
            <div class="main-cards">
                <ProgramVersionTargetsCard :program-version-targets="targets"/>
                <ProgramVersionDesignsCard />
            </div>
            <div class="side-cards">
                <ProgramVersionGoalDescriptionCard :goal="programVersion.goal" :description="programVersion.description"/>
                <ProgramVersionApplicationsCard :applications="applications" />
                <ProgramVersionTeamCard :program-version-team="team" />
            </div>
        </div>
    </template>
</template>
<script setup lang="ts">
import { defineProps, computed } from 'vue';
import AvatarsActivity from './AvatarsActivity.vue';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import ProgramVersionTargetsCard from './ProgramVersionTargetsCard.vue';
import ProgramApplicationCard from './ProgramApplicationCard.vue'
import ProgramTechniquesCard from './ProgramTechniquesCard.vue';
import ProgramLayersDesign from './ProgramLayersDesign.vue';
import ProgramVersionGoalDescriptionCard from './ProgramVersionGoalDescriptionCard.vue';
import { useInitialsToUserLogo } from '@/composables/useInitialsToUserLogo';
import moment from 'moment';
import { ApplicationTypesInterface, ProgramVersionTeamInterface, TargetInterface } from '@/models/programVersionsInterface';
import { programMockupIterface } from '@/models/programsInterface';
import ProgramVersionApplicationsCard from './ProgramVersionApplicationsCard.vue';
import ProgramVersionTeamCard from './ProgramVersionTeamCard.vue'
import ProgramVersionDesignsCard from './ProgramVersionDesignsCard.vue';
import ProgramVersionDetailHeader from './ProgramVersionDetailHeader.vue';
// import ProgramTechnologiesCard from './ProgramTechnologiesCard.vue';


const props = defineProps<{
    programDetailInfoMockup?: programMockupIterface,
    programVersion?,
    isMockup: boolean
}>();

const usersActivity = computed(() => {
    const parsedUsers = [];
    if (!props.isMockup) {
        const createdBy = {
            title: 'Created by',
            date: props.programVersion.creationDate ? moment(props.programVersion.creationDate).format('DD MMM YYYY') : null,
            personName: props.programVersion.createdBy.username,
            initials: props.programVersion.createdBy.firstName && props.programVersion.createdBy.lastName ? useInitialsToUserLogo(props.programVersion?.createdBy.firstName, props.programVersion?.createdBy.lastName) : "A"
        }
        parsedUsers.push(createdBy);
        if(props.programVersion.updatedBy) {
            const updatedAt = {
                title: 'Updated by',
                date: props.programVersion.updatedAt ? moment(props.programVersion.updatedAt).format('DD MMM YYYY') : null,
                personName: props.programVersion.updatedBy.username,
                initials: props.programVersion.updatedBy.firstName && props.programVersion.updatedBy.lastName ? useInitialsToUserLogo(props.programVersion?.updatedBy.firstName, props.programVersion?.updatedBy.lastName) : "A"
            }
            parsedUsers.push(updatedAt)
        }
    }
    return parsedUsers;
})

const team = computed<ProgramVersionTeamInterface[]>(() => props.programVersion.team.edges.length ? props.programVersion.team.edges.map((edge) => edge.node) : []);

const applications = computed<ApplicationTypesInterface[]>(() => props.programVersion.applicationTypes.edges.length ? props.programVersion.applicationTypes.edges.map((edge) => edge.node) : []);

const allTargets = computed<TargetInterface[]>(() => props.programVersion.targets.edges.length ? props.programVersion.targets.edges.map((edge) => edge.node) : [])
// Remove targets with null values as props due to harmonization
const targets = computed<TargetInterface[]>(() => allTargets.value.filter(target => target.targetProp));

</script>
<style lang="scss" scoped>
.avatars {
    margin-top: -2rem;
}
.program-detail-content {
    display: flex;
    justify-content: space-between;

    .main-cards {
        flex-basis: 70%;
        display: flex;
        flex-direction: column;
    }

    .side-cards {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
    }
}</style>