<template>
  <div class="round-action-icon" :class="{active: isOpen}">
    <PlusIcon class="icon icon-small" @click="openModal" />
  </div>
  <ModalSlots :show="isOpen" dialogClass="create-asset-modal" @close-modal="closeModal">
    <template v-slot:header><p></p></template>
    <template v-slot:body>
        <LoaderPage v-if="showFilesUploadLoader" loader-text="Creating Asset" gif-position="center"/>
        <TabGroup vertical @change="assetTypeSelected">
            <div class="new-asset-container">   
                <TabList class="vertical-tab-list">
                    <p class="form-subtitle">Add New</p>
                    <Tab v-for="assetType in assetTypesList" :key="assetType.key">{{assetType.displayValue}}</Tab>
                </TabList>
                <TabPanels class="forms-container">
                    <TabPanel><TechnologyAssetsAddNewOtherTechnology :form-submited="formSubmited && formSubmitedType == assetType" :form-is-valid="formIsValid" :errors-fields="missingFields" :errors-during-mutation="errorsDuringMutation['otherTechnology']" /></TabPanel>
                    <TabPanel><TechnologyAssetsAddNewPatent :form-submited="formSubmited && formSubmitedType == assetType" :form-is-valid="formIsValid" :errors-fields="missingFields" :errors-during-mutation="errorsDuringMutation['patent']"/></TabPanel>
                    <TabPanel><TechnologyAssetsAddNewResearchPaper :form-submited="formSubmited && formSubmitedType == assetType" :form-is-valid="formIsValid" :errors-fields="missingFields" /></TabPanel>
                    <TabPanel><TechnologyAssetsAddNewResearchProject :form-submited="formSubmited && formSubmitedType == assetType" :form-is-valid="formIsValid" :errors-fields="missingFields" /></TabPanel>                 
                </TabPanels>
            </div>
        </TabGroup>
    </template>
    <template v-slot:footer>
        <button class="button cancel" @click="closeModal">cancel</button>
        <button class="button green-accept" @click="createAssetAndClose" :disabled="createInProgress" :class="{ 'cursor-not-allowed hover:shadow-none opacity-75': createInProgress }">Create</button>
        <button class="button green-more" @click="createAsssetAndAddMore" :disabled="createInProgress" :class="{ 'cursor-not-allowed hover:shadow-none opacity-75': createInProgress }">create and add more</button>
    </template>
  </ModalSlots>
</template>

<script setup lang="ts">
import ModalSlots from "@/components/ModalSlots.vue";
import TechnologyAssetsAddNewPatent from "@/components/TechnologyAssetsAddNewPatent.vue";
import TechnologyAssetsAddNewResearchPaper from '@/components/TechnologyAssetsAddNewResearchPaper.vue';
import TechnologyAssetsAddNewResearchProject from '@/components/TechnologyAssetsAddNewResearchProject.vue';
import TechnologyAssetsAddNewOtherTechnology from '@/components/TechnologyAssetsAddNewOtherTechnology.vue';
import { PlusIcon } from "@heroicons/vue/24/outline";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { computed, ref } from "vue";
import { ASSET_TYPES } from '@/models/technologyAssetConstants';
import { useStore } from "vuex";
import { useFormValidationNewAsset } from "@/composables/useFormValidationNewAsset";
import { NEW_ASSET_FIELDS } from "@/models/newAssetFormFields";
import { useRouter } from "vue-router";
import LoaderPage from "./LoaderPage.vue";

const store = useStore();
const router = useRouter();
const isOpen = ref(false);
const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');
const createInProgress = ref(false);

const assetTypesList = [ASSET_TYPES.OTHER, ASSET_TYPES.PATENT, ASSET_TYPES.RESEARCH_PAPER, ASSET_TYPES.RESEARCH_PROJECT]
const assetType = ref(assetTypesList[0].graphqlValue)

const closeModal = () => { isOpen.value = false; formSubmitedType.value = null; }
const openModal = () => {
    if (!collapsed.value) {
        toggleSidebar()
    }
    isOpen.value = true; 
}
const assetTypeSelected = (index:number) =>  assetType.value = assetTypesList[index].graphqlValue;
const newAsset = computed(() => store.state.assetCreationModule.asset[assetType.value]);
const newUrl = computed(() => store.state.assetCreationModule.newAssetUrl);
const errorsDuringMutation = computed(() => store.state.assetCreationModule.errorsDuringMutation) 
const formSubmited = ref(false);
const formSubmitedType = ref();

const missingFields = computed(() => useFormValidationNewAsset(newAsset.value, assetType.value).missingFields.value);
const formIsValid = computed(() => useFormValidationNewAsset(newAsset.value, assetType.value).valid.value);

const showFilesUploadLoader = computed(() => store.getters['assetCreationModule/getShowFilesUploadLoader']);

const createAssetAndClose = async () => {
    formSubmited.value = true;
    formSubmitedType.value = assetType.value; 
    if ( formIsValid.value ) {
        const assetToCreate = assetTypesList.find(at => at.graphqlValue === assetType.value);
        const mutation = NEW_ASSET_FIELDS[assetToCreate.key].createMutation;
        createInProgress.value = true;
        await store.dispatch(mutation)
            .then(() => {
                formSubmited.value = false;
                formSubmitedType.value = null;
                createInProgress.value = false;
                store.commit('assetsModule/updateTabSelection', {selectedTab: assetToCreate});
                if (newUrl.value) {
                    router.push({ name: 'TechnologyAssetDetail', params: { id: newUrl.value }});
                }
            })
            .catch(() => {
                createInProgress.value = false;
            })
    }
}

const createAsssetAndAddMore = async () => {
    formSubmited.value = true;
    formSubmitedType.value = assetType.value; 
    if ( formIsValid.value ) {
        const assetToCreate = assetTypesList.find(at => at.graphqlValue === assetType.value);
        const mutation = NEW_ASSET_FIELDS[assetToCreate.key].createMutation;
        createInProgress.value = true;
        await store.dispatch(mutation)
            .then(() => {
                formSubmited.value = false;
                formSubmitedType.value = null;
                createInProgress.value = false;
                store.commit('assetsModule/updateTabSelection', {selectedTab: assetToCreate})
            })
            .catch(() => {
                createInProgress.value = false;
             } )
    }
}

</script>
<style lang="scss" scoped>
    .new-asset-container {
        display: flex;
    }
    .vertical-tab-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-right: $border;
        background-color: $grey-modal;
        flex-basis: 18%;
        button {
            width: 100%;
            padding: 1rem;
            text-align: left;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.8rem;
            color: $font-color-light;
            &:focus-visible {outline: none;}
            &[aria-selected="true"] {
                background-color: $one-five-green;
                color: $grey-super-light;
            }
        }
        .form-subtitle{
            background-color: transparent;
            padding: 1rem;
        }
    }
    .forms-container {
        flex-basis: 82%;
        background: #fff;
        padding: 0rem 2rem 4rem;
        max-height: 70vh;
        overflow: scroll;
    }
    .modal-footer.two-buttons button {
        margin: 0 2rem;
        font-weight: 500;
        font-size: 0.9rem;
    }
</style>