import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { useMutation } from "@vue/apollo-composable";
import {CHAT_CREATE,CHAT_DELETE, CHAT_RENAME} from "@/grapql/aiMutation";
import {useNodeToChat} from "@/composables/aiComposables";
import { IChat, IChatMessage, IMessageContent } from "@/models/aiInterfaces";
import { ChatKey, MessageType } from "@/models/aiModels";

provideApolloClient(apolloClient);

const getDefaultState = () => {
    return {
        chats: [], // all chats history
        chatMessages: [],    // current active chat messages
    }
}
const state= getDefaultState();

const mutations = {
    // set all chats history
    setChats(state,chats){
        state.chats = chats;
    },
    // set the current chat messages
    setChatMessages(state,messages){
        state.chatMessages = messages;
    },
    // add a new chat to the start of chats and set it as current chat
    addChat(state,chat){
        state.chats.unshift(chat);
    },
    // add a new message to the current chat
    addChatMessage(state,message: IChatMessage){
        //check if message already exists
        const existingMessage = state.chatMessages.find(msg => msg.id === message.id);
        if(!existingMessage)
        {
            state.chatMessages.push(message);
        }
    },
    // add a new message content to the current chat message
    addMessageContent(state,payload:{chatId,messageId,messageType:MessageType,content:IMessageContent}){
        const existingMessage = state.chatMessages.find(message => message.id === payload.messageId);
        // if message is found, add the content to the message
        if(existingMessage){
            // check if the content is already present
            const existingContent = existingMessage.messageContents.find(content => content.id === payload.content.id);
            if(!existingContent)
            {
                existingMessage.messageContents.push(payload.content);
            }
        }
        // if message is not found, create a new message and add the content
        else{
            const newMessage: IChatMessage = {
                id: payload.messageId,
                chatId: payload.chatId,
                messageType: payload.messageType,
                createdAt: new Date(),
                messageContents: [payload.content],
            }
            state.chatMessages.push(newMessage);
            state.chatMessages = [...state.chatMessages];
        }
    },

    // delete a chat    
    deleteChat(state,chatId){
        state.chats = state.chats.filter(chat => chat.id !== chatId);
    },

    // rename a chat
    renameChat(state,payload:{chatId:string,name:string}){
        const chat = state.chats.find(chat => chat.id === payload.chatId);
        if(chat){
            chat.name = payload.name;
        }
    },

    // Reset the state to default
    resetState(state){
        Object.assign(state,getDefaultState());
    },

}

const actions = {
    // create a new chat and set it as current chat
    async createChat({commit},chatKey:ChatKey): Promise<string>{
        return new Promise<string>((resolve,reject) => {

            const { mutate: createChat, onDone, onError } = useMutation(CHAT_CREATE);
            createChat({input: {chatKey:chatKey}});

            onDone((result) => {
                if(result?.data?.chatCreate?.chat){
                    // add the chat to the start of chats
                    commit('addChat',useNodeToChat(result.data.chatCreate.chat));
                    resolve(result.data.chatCreate.chat.id);
                }
                else{
                    reject('Chat creation failed');
                }
            });
            onError((error) => {
                reject(error);
            });
            
        });
    },
    
    // delete chat
    async deleteChat({commit},chatId): Promise<boolean>{
        return new Promise<boolean>((resolve,reject) => {
            // delete chat
            const {mutate: deleteChat, onDone,onError} = useMutation(CHAT_DELETE);
            deleteChat({input:{id:chatId}});

            onDone(() => {
                commit('deleteChat',chatId);
                resolve(true);
            });
            
            onError((error) => {
                reject(false);
            });
        });
    },

    // rename chat
    async renameChat({commit},payload:{chatId:string,name:string}): Promise<boolean>{
        return new Promise<boolean>((resolve,reject) => {
            // rename chat
            const {mutate: renameChat, onDone,onError} = useMutation(CHAT_RENAME);
            renameChat({input:{id:payload.chatId,name:payload.name}});

            onDone(() => {
                commit('renameChat',payload);
                resolve(true);
            });
            
            onError((error) => {
                reject(false);
            });
        });
    }
}
const getters = {
    // get all chats
    getAllChats(state): IChat[]{
        return state.chats;
    },
    // get the current chat
    getChat(state,chatId): IChat{
        return state.chats.find(chat => chat.id === chatId)  as IChat;
    },
    // get the current chat messages
    getChatMessages(state): IChatMessage[]{
        return state.chatMessages;
    },
    // get last messageId of chatMessages
    getLastMessageId(state): string{
        return state.chatMessages.length > 0 ? state.chatMessages[state.chatMessages.length - 1].id : null;
    },
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
