<template>
   <!-- TODO: resume the TDS process modal -->
  <!-- <assetTdsModal :assetPdfFiles="assetPdfFiles"/> -->
  <template
    v-if="!assetAssessmentHighLevelData.files.length && !assetAssessmentHighLevelData.fields.some(aahld => aahld.content)">
    <div>
      <p class="no-assessment">No Assessment yet</p>
      <div class="edit-assessment on-green" @click="handleEdit">
        <PencilIcon class="icon" />
        <p>Assess asset</p>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="edit-assessment" @click="handleEdit">
      <PencilIcon class="icon" />
      <p>Assess</p>
    </div>
    <template v-for="field in assetAssessmentHighLevelData.fields" :key="field.title">
      <div v-if="field.content" class="mb-4">
        <p class="field-title">{{ field.title }}</p>

        <template v-if="field.inputType == 'number'">
          <span class="value-number"> {{ field.content }} </span>
        </template>

        <template v-if="field.inputType == 'select' || field.inputType == 'multipleSelect'">
          <!-- STATUS -->
          <div v-if="field.title === 'Status'">
            <p class="status" :class="field.content">{{ field.content }}</p>
          </div>

          <!-- REST OF THE PROPERTIES THAT HAS LIST -->
          <ul v-else class="values-list">
            <li v-for="value in field.content" :key="value">{{ value }}</li>
          </ul>
        </template>
        
        <template v-if="field.inputType == 'textarea'">
          <p v-html="field.content" class="notes"></p>
        </template>
      </div>
    </template>
    <p v-if="!!assetAssessmentHighLevelData.files.length" class="field-title">files</p>
    <AssetFilesList v-if="!!assetAssessmentHighLevelData.files.length" :files-to-show="assetAssessmentHighLevelData.files"
      :files-removal="false" :show-label="false" />
  </template>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';
import AssetFilesList from './AssetFilesList.vue';
import { PencilIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { AssetAssessmentHighLevel } from '@/models/assetAssessmentInterfaces';
// import AssetTdsModal from './AssetTdsModal.vue';
// import {useAssetFilesToPdfFilesOnly} from '@/composables/useAssetFilesToPdfFilesOnly';

defineProps<{ assetAssessmentHighLevelData: AssetAssessmentHighLevel }>()
const store = useStore();

const handleEdit = () => store.commit('assetDetailModule/setEditableOn', { sectionToMakeEditbale: 'highLevel' })


// const assetId=computed(()=>store.getters['assetDetailModule/getAssetId']);
// const assetPdfFiles=computed(()=>useAssetFilesToPdfFilesOnly(props.assetAssessmentHighLevelData.files))

</script>
<style lang="scss" scoped>
.field-title {
  font-weight: 500;
  text-transform: uppercase;
  color: $font-color-light;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.status {
  background-color: $danger-light;
  color: white;
}

.label {
  font-size: 0.85rem;
  color: $font-color-super-light;
  font-weight: 300;
}

.value {
  font-size: 0.85rem;
  color: rgb(109, 109, 109);
  font-weight: 500;
  padding-bottom: 0.5rem;
  overflow: auto;
  text-transform: uppercase;
  overflow: visible;
}

.property-category {
  border-left: 1px dashed #ddd;
  margin-left: 1.2rem;
}
</style>