import gql from "graphql-tag";

export const ASSET_DATA_FOR_COMPARISON_TABLE = gql`
    query getAssetsDataForComparisonTable ($id: ID!){
        asset(id: $id) {
            id
            nickname
            title
            assetProps {
                edges {
                    node {
                        id
                        prop {
                        id
                        name
                        propCategory {
                            id
                            name
                            propClass {
                                id
                                name
                            }
                        }
                    }
                    extraInformation
                    value1
                    value2
                    valueType {
                        id
                        name
                        symbol
                        description
                    }
                    valueUnit {
                        id
                        name
                        abbreviation
                        description            
                    }
                }
                    }
                }
            }
        }
    


`