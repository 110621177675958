import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "field-title" }
const _hoisted_4 = {
  key: 0,
  class: "value-number"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "values-list"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "field-title"
}


import AssetFilesList from './AssetFilesList.vue';
import { PencilIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { AssetAssessmentHighLevel } from '@/models/assetAssessmentInterfaces';
// import AssetTdsModal from './AssetTdsModal.vue';
// import {useAssetFilesToPdfFilesOnly} from '@/composables/useAssetFilesToPdfFilesOnly';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentHighLevel',
  props: {
    assetAssessmentHighLevelData: {}
  },
  setup(__props: any) {


const store = useStore();

const handleEdit = () => store.commit('assetDetailModule/setEditableOn', { sectionToMakeEditbale: 'highLevel' })


// const assetId=computed(()=>store.getters['assetDetailModule/getAssetId']);
// const assetPdfFiles=computed(()=>useAssetFilesToPdfFilesOnly(props.assetAssessmentHighLevelData.files))


return (_ctx: any,_cache: any) => {
  return (!_ctx.assetAssessmentHighLevelData.files.length && !_ctx.assetAssessmentHighLevelData.fields.some(aahld => aahld.content))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "no-assessment" }, "No Assessment yet", -1)),
        _createElementVNode("div", {
          class: "edit-assessment on-green",
          onClick: handleEdit
        }, [
          _createVNode(_unref(PencilIcon), { class: "icon" }),
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "Assess asset", -1))
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("div", {
          class: "edit-assessment",
          onClick: handleEdit
        }, [
          _createVNode(_unref(PencilIcon), { class: "icon" }),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Assess", -1))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetAssessmentHighLevelData.fields, (field) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: field.title
          }, [
            (field.content)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(field.title), 1),
                  (field.inputType == 'number')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(field.content), 1))
                    : _createCommentVNode("", true),
                  (field.inputType == 'select' || field.inputType == 'multipleSelect')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (field.title === 'Status')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("p", {
                                class: _normalizeClass(["status", field.content])
                              }, _toDisplayString(field.content), 3)
                            ]))
                          : (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.content, (value) => {
                                return (_openBlock(), _createElementBlock("li", { key: value }, _toDisplayString(value), 1))
                              }), 128))
                            ]))
                      ], 64))
                    : _createCommentVNode("", true),
                  (field.inputType == 'textarea')
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 2,
                        innerHTML: field.content,
                        class: "notes"
                      }, null, 8, _hoisted_7))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (!!_ctx.assetAssessmentHighLevelData.files.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, "files"))
          : _createCommentVNode("", true),
        (!!_ctx.assetAssessmentHighLevelData.files.length)
          ? (_openBlock(), _createBlock(AssetFilesList, {
              key: 1,
              "files-to-show": _ctx.assetAssessmentHighLevelData.files,
              "files-removal": false,
              "show-label": false
            }, null, 8, ["files-to-show"]))
          : _createCommentVNode("", true)
      ], 64))
}
}

})