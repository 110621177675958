import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex items-center pl-4" }
const _hoisted_2 = { class: "filter-rules p-4" }
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["onUpdate:modelValue", "onChange"]
const _hoisted_6 = ["disabled", "value"]
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 3,
  class: "w-full ml-0 min-w-[40%]"
}
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "flex items-center justify-between p-4 pt-2 pb-2" }

import { AdjustmentsVerticalIcon, PlusCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { ASSETS_FILTER_OPTIONS } from '@/grapql/assetsFilterOptionsQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { ColumnAssetInterface, FilterConditionInterface, LookupOperatorsInterface } from '@/models/technologyAssetsInterface';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex';
import DropdownSearchableMultiple from './DropdownSearchableMultiple.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsFilterFilterOptions',
  setup(__props) {

const store = useStore();
const columns = computed<ColumnAssetInterface[]>(() => store.getters['assetsModule/getAssetsTableColumns']);
const allConditions = computed<FilterConditionInterface[]>(() => store.state.assetsModule.filterFormConditions);
const logicalOperators = computed<string[]>(() => store.state.assetsModule.logicalOperators);
const lookupOperators = computed<LookupOperatorsInterface[]>(() => store.state.assetsModule.lookupOperators);
const lookupOperatorsForDropDown = computed<LookupOperatorsInterface[]>(() => store.state.assetsModule.lookupOperatorsForDropDown);

// Inizialize the form with options values 
const fieldsToQuery = computed<string[]>(() => columns.value.filter((col:ColumnAssetInterface) => col.hasQuery ).map((col:ColumnAssetInterface) => { return col.field }) );
const { result } = useQuery(ASSETS_FILTER_OPTIONS);
const inizilizedData = computed(() => result?.value ?? null);
const optionsValues = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsToQuery.value));

const onSearcheableValueChange = (index: number) => {
    allConditions.value[index].valueToSearch = '';
    allConditions.value[index].multipleValuesToSearch = [];
}

const addCondition = () => {
    const newCondition:FilterConditionInterface =  {
        searchableValue: null,
        lookupOperator: lookupOperators.value[0].value,
        valueToSearch: '',
        logicalOperator: logicalOperators.value[0],
        lookupOperatorsForDropDown: lookupOperatorsForDropDown.value[0].value,
        multipleValuesToSearch: []
    }
    allConditions.value.push(newCondition);
}

const removeCondition = (index: number) => allConditions.value.splice(index, 1);
    
const inizializeFilter = () => {
    if (allConditions.value.length == 0) { 
        addCondition();
    } 
}

const clearAllConditions = () => {
    allConditions.value.splice(0,allConditions.value.length);
    inizializeFilter();
}

onMounted(() => {
    inizializeFilter();
})

onUpdated(() => {
    inizializeFilter();
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(AdjustmentsVerticalIcon), { class: "icon icon-small" }),
      _cache[0] || (_cache[0] = _createElementVNode("p", null, "Filter assets where: ", -1))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allConditions.value, (condition, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "first-rule items-start flex mb-4"
        }, [
          (index !== 0)
            ? _withDirectives((_openBlock(), _createElementBlock("select", {
                key: 0,
                "onUpdate:modelValue": ($event: any) => ((condition.logicalOperator) = $event),
                class: "w-auto ml-0 mr-2"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logicalOperators.value, (logicalOperator) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: logicalOperator,
                    value: logicalOperator
                  }, _toDisplayString(logicalOperator), 9, _hoisted_4))
                }), 128))
              ], 8, _hoisted_3)), [
                [_vModelSelect, condition.logicalOperator]
              ])
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": ($event: any) => ((condition.searchableValue) = $event),
            onChange: ($event: any) => (onSearcheableValueChange(index)),
            class: "w-full ml-0"
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("option", {
              disabled: "",
              value: null
            }, "Select property", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (column) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: column.field
              }, [
                (column.filtrable)
                  ? (_openBlock(), _createElementBlock("option", {
                      key: 0,
                      disabled: (allConditions.value.some((condition) => condition.searchableValue == column)) && (column.hasQuery || column.field == 'assetType'),
                      value: column
                    }, _toDisplayString(column.label), 9, _hoisted_6))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 40, _hoisted_5), [
            [
              _vModelSelect,
              condition.searchableValue,
              void 0,
              { lazy: true }
            ]
          ]),
          (condition.searchableValue && (condition.searchableValue.hasQuery || condition.searchableValue.field === 'assetType'))
            ? _withDirectives((_openBlock(), _createElementBlock("select", {
                key: 1,
                "onUpdate:modelValue": ($event: any) => ((condition.lookupOperatorsForDropDown) = $event),
                class: "w-28 mx-2"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lookupOperatorsForDropDown.value, (lookupOperator) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: lookupOperator.value,
                    value: lookupOperator.value
                  }, _toDisplayString(lookupOperator.label), 9, _hoisted_8))
                }), 128))
              ], 8, _hoisted_7)), [
                [_vModelSelect, condition.lookupOperatorsForDropDown]
              ])
            : _withDirectives((_openBlock(), _createElementBlock("select", {
                key: 2,
                "onUpdate:modelValue": ($event: any) => ((condition.lookupOperator) = $event),
                class: "w-28 mx-2"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lookupOperators.value, (lookupOperator) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: lookupOperator.value,
                    value: lookupOperator.value
                  }, _toDisplayString(lookupOperator.label), 9, _hoisted_10))
                }), 128))
              ], 8, _hoisted_9)), [
                [_vModelSelect, condition.lookupOperator]
              ]),
          (condition.searchableValue && condition.searchableValue.hasQuery)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(DropdownSearchableMultiple, {
                  "options-list": optionsValues.value[`${condition.searchableValue.field}Options`],
                  modelValue: condition.multipleValuesToSearch,
                  "onUpdate:modelValue": ($event: any) => ((condition.multipleValuesToSearch) = $event),
                  "select-other-than-id": 'name'
                }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"])
              ]))
            : _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 4,
                class: "w-full min-w-[40%]",
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((condition.valueToSearch) = $event)
              }, null, 8, _hoisted_12)), [
                [_vModelText, condition.valueToSearch]
              ]),
          _createElementVNode("div", {
            class: "remove-condition ml-2",
            onClick: ($event: any) => (removeCondition(index))
          }, [
            _createVNode(_unref(TrashIcon), { class: "icon icon-x-small thick light" })
          ], 8, _hoisted_13)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("button", {
        class: "add-condition",
        onClick: addCondition
      }, [
        _createVNode(_unref(PlusCircleIcon), { class: "icon icon-small light thick mr-2" }),
        _cache[2] || (_cache[2] = _createTextVNode(" Add condition "))
      ]),
      (allConditions.value.length > 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "add-condition",
            onClick: clearAllConditions
          }, [
            _createVNode(_unref(XCircleIcon), { class: "icon icon-small light thick mr-2" }),
            _cache[3] || (_cache[3] = _createTextVNode(" Clear all "))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})