import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-white mb-4 p-4" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex overflow-x-auto whitespace-nowrap border-b scrollbar-hide pb-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "grid grid-cols-7 gap-2 mt-4" }
const _hoisted_6 = { class: "col-span-5 program-detail-card mb-4 relative" }
const _hoisted_7 = { class: "cards-title ml-4 mt-10" }
const _hoisted_8 = { class: "mb-4 px-2 grid grid-cols-9 items-center justify-around" }
const _hoisted_9 = { class: "col-span-9 mt-4 justify-center flex" }
const _hoisted_10 = {
  class: "layers",
  viewBox: "-100 -45 350 120",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_11 = ["d", "fill", "filter"]
const _hoisted_12 = ["d"]
const _hoisted_13 = ["d"]
const _hoisted_14 = ["y1", "y2"]
const _hoisted_15 = ["y"]
const _hoisted_16 = ["y"]
const _hoisted_17 = ["y1", "y2"]
const _hoisted_18 = ["href"]
const _hoisted_19 = ["y"]
const _hoisted_20 = ["y"]
const _hoisted_21 = { class: "col-span-5 border-t" }
const _hoisted_22 = { class: "grid grid-flow-col divide-x" }
const _hoisted_23 = {
  key: 0,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_24 = { class: "font-medium text-black" }
const _hoisted_25 = {
  key: 1,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_26 = { class: "font-medium text-black" }
const _hoisted_27 = {
  key: 2,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_28 = { class: "font-medium text-black" }
const _hoisted_29 = {
  key: 3,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_30 = { class: "col-span-2 program-detail-card mb-4" }
const _hoisted_31 = { class: "grid grid-rows-7 h-full" }
const _hoisted_32 = {
  key: 0,
  class: "row-span-3 flex border-b"
}
const _hoisted_33 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_34 = { class: "flex justify-between border-y text-xs" }
const _hoisted_35 = { class: "font-bold text-right" }
const _hoisted_36 = { class: "italic text-xs font-light" }
const _hoisted_37 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_38 = { class: "font-bold text-right" }
const _hoisted_39 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_40 = { class: "font-bold text-right inline-flex" }
const _hoisted_41 = {
  key: 1,
  class: "row-span-3 flex border-b"
}
const _hoisted_42 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_43 = { class: "flex justify-between border-y text-xs" }
const _hoisted_44 = { class: "font-bold text-right" }
const _hoisted_45 = { class: "italic text-xs font-light" }
const _hoisted_46 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_47 = { class: "font-bold text-right" }
const _hoisted_48 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_49 = { class: "font-bold text-right inline-flex" }
const _hoisted_50 = {
  key: 2,
  class: "row-span-3 flex border-b"
}
const _hoisted_51 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_52 = { class: "flex justify-between border-y text-xs" }
const _hoisted_53 = { class: "font-bold text-right" }
const _hoisted_54 = { class: "italic text-xs font-light" }
const _hoisted_55 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_56 = { class: "font-bold text-right" }
const _hoisted_57 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_58 = { class: "font-bold text-right inline-flex" }
const _hoisted_59 = {
  key: 3,
  class: "row-span-3 flex"
}
const _hoisted_60 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_61 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_62 = { class: "font-bold text-right" }
const _hoisted_63 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_64 = { class: "font-bold text-right" }
const _hoisted_65 = {
  key: 2,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_66 = { class: "font-bold text-right" }
const _hoisted_67 = {
  key: 3,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_68 = { class: "font-bold text-right" }

import { computed,ref } from 'vue';
import AIConfidenceIcon from './AIConfidenceIcon.vue';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';
const startingPoint = 50;
const inclination = 18;


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatGroupedSandwichesRenderer',
  props: {
    contents: {
        type: [Object],
        required: true
    }
},
  setup(__props) {

const props = __props

// Create a computed property to modify and return the entire content
const sandwiches = computed(() => {
    return props.contents.map((content) => {
        // Clone the entire content object to avoid mutating the prop directly
        const contentClone = { ...content };
        //store cumulative thickness to be able to calculate the offset
        let cumulativeThickness = 0;
        // Modify the compositions array within the cloned content
        contentClone.composition = contentClone.composition.map((item,index) =>  {
            const thickness = item.thickness ?? calcThickness(item.gsm ?? 1);
            const offset = cumulativeThickness + (index * baseOffset(contentClone.composition));
            cumulativeThickness += thickness;
            return {
                ...item,
                offset: offset,
                thickness: thickness,
                color: colors[index % colors.length],
            };
        });
        // Return the modified content object
        return contentClone;
    });
});


const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc'];
const baseOffset = (composition) => {
    const layersCount = composition.length;
    if (layersCount < 4) {
        return 10;
    } else if (layersCount < 8) {
        return 8;
    } else {
        return 6;
    }
};

const generatAssetLink = (assetLink) =>{
    
    if(!assetLink) return null;

    return '/technology-assets-repo/'+assetLink;
}

const activeTab = ref(0);
const getTabName = (name) => {
    return name.length > 13 ? name.substring(0, 10) + '...' : name;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sandwiches.value, (sandwich, index) => {
          return (_openBlock(), _createElementBlock("button", {
            key: index,
            onClick: ($event: any) => (activeTab.value = index),
            class: _normalizeClass(["px-4 py-2 text-sm font-medium border-b-2 transition-all duration-200 flex-shrink-0", activeTab.value === index ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700'])
          }, _toDisplayString(getTabName(sandwich.sample)), 11, _hoisted_4))
        }), 128))
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sandwiches.value, (sandwich, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(sandwich.sample), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(), _createElementBlock("svg", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sandwich.composition, (layer, index) => {
                    return (_openBlock(), _createElementBlock("g", {
                      key: index,
                      class: "layer"
                    }, [
                      _createElementVNode("g", {
                        style: _normalizeStyle(`--offset-y:-${layer.offset}px;`)
                      }, [
                        _createElementVNode("path", {
                          d: `M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset - layer.thickness - inclination} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} L50,${startingPoint - layer.offset + inclination} Z`,
                          fill: layer.color,
                          filter: layer.color
                        }, null, 8, _hoisted_11),
                        _createElementVNode("path", {
                          d: `M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset + inclination - layer.thickness} v${layer.thickness} Z`
                        }, null, 8, _hoisted_12),
                        _createElementVNode("path", {
                          d: `M50,${startingPoint - layer.offset + inclination} v-${layer.thickness} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} Z`
                        }, null, 8, _hoisted_13)
                      ], 4),
                      _createElementVNode("line", {
                        x1: -10,
                        y1: startingPoint - layer.offset - (layer.thickness / 2),
                        x2: -100,
                        y2: startingPoint - layer.offset - (layer.thickness / 2),
                        stroke: "black",
                        "stroke-width": "0.1",
                        "stroke-dasharray": "3,6"
                      }, null, 8, _hoisted_14),
                      _createElementVNode("text", {
                        x: -100,
                        y: startingPoint - layer.offset - (layer.thickness / 2) + -1
                      }, _toDisplayString(layer.layerType), 9, _hoisted_15),
                      (layer.gsm !== null)
                        ? (_openBlock(), _createElementBlock("text", {
                            key: 0,
                            x: -10,
                            y: startingPoint - layer.offset - (layer.thickness / 2) + -1,
                            "text-anchor": "end"
                          }, _toDisplayString(layer.gsm) + " gsm", 9, _hoisted_16))
                        : _createCommentVNode("", true),
                      _createElementVNode("line", {
                        x1: 110,
                        y1: startingPoint - layer.offset - (layer.thickness / 2),
                        x2: 250,
                        y2: startingPoint - layer.offset - (layer.thickness / 2),
                        stroke: "black",
                        "stroke-width": "0.1",
                        "stroke-dasharray": "3,6"
                      }, null, 8, _hoisted_17),
                      _createElementVNode("a", {
                        href: generatAssetLink(layer.assetLink),
                        target: "_blank",
                        class: _normalizeClass({'disabled cursor-not-allowed': !layer.assetLink})
                      }, [
                        _createElementVNode("text", {
                          x: 110,
                          y: startingPoint - layer.offset - (layer.thickness / 2) + -1
                        }, _toDisplayString(layer.layerName), 9, _hoisted_19)
                      ], 10, _hoisted_18),
                      (layer.processingTechnique != null && layer.processingTechnique != 'N/A')
                        ? (_openBlock(), _createElementBlock("text", {
                            key: 1,
                            x: 250,
                            y: startingPoint - layer.offset - (layer.thickness / 2) + -1,
                            "text-anchor": "end",
                            style: _normalizeStyle('font-size: ' + (layer.processingTechnique.length > 20 ? '5px' : '6px'))
                          }, _toDisplayString(layer.processingTechnique), 13, _hoisted_20))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                (sandwich.gsmTotal)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createElementVNode("p", _hoisted_24, _toDisplayString(sandwich.gsmTotal) + " GSM", 1),
                      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "total grammage", -1))
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.gsmPaper)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("p", _hoisted_26, _toDisplayString(sandwich.gsmPaper) + " GSM", 1),
                      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "paper grammage", -1))
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.paperContent)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("p", _hoisted_28, _toDisplayString(sandwich.paperContent) + " %", 1),
                      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "Paper content", -1))
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.sealingSides)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, _cache[3] || (_cache[3] = [
                      _createElementVNode("p", { class: "font-medium text-black" }, ".", -1),
                      _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "sealing sides", -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              (sandwich.otr.min)
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                      _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "OTR")
                    ], -1)),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "VALUE:", -1)),
                        _createElementVNode("span", _hoisted_35, [
                          _createTextVNode(_toDisplayString(sandwich.otr.min) + " ", 1),
                          (sandwich.otr.max)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" - " + _toDisplayString(sandwich.otr.max), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_36, "  " + _toDisplayString(sandwich.otr.unit), 1)
                        ])
                      ]),
                      (sandwich.otr.condition)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONDITION:", -1)),
                            _createElementVNode("span", _hoisted_38, _toDisplayString(sandwich.otr.condition), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (sandwich.otr.confidence)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONFIDENCE:", -1)),
                            _createElementVNode("span", _hoisted_40, [
                              _createVNode(AIConfidenceIcon, {
                                confidence: sandwich.otr.confidence
                              }, null, 8, ["confidence"]),
                              _createTextVNode("   " + _toDisplayString(sandwich.otr.confidence), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (sandwich.wvtr.min)
                ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                      _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "WVTR")
                    ], -1)),
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("div", _hoisted_43, [
                        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "VALUE:", -1)),
                        _createElementVNode("span", _hoisted_44, [
                          _createTextVNode(_toDisplayString(sandwich.wvtr.min) + " ", 1),
                          (sandwich.wvtr.max)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" - " + _toDisplayString(sandwich.wvtr.max), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_45, "  " + _toDisplayString(sandwich.wvtr.unit), 1)
                        ])
                      ]),
                      (sandwich.wvtr.condition)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONDITION:", -1)),
                            _createElementVNode("span", _hoisted_47, _toDisplayString(sandwich.wvtr.condition), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (sandwich.wvtr.confidence)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                            _cache[10] || (_cache[10] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONFIDENCE:", -1)),
                            _createElementVNode("span", _hoisted_49, [
                              _createVNode(AIConfidenceIcon, {
                                confidence: sandwich.wvtr.confidence
                              }, null, 8, ["confidence"]),
                              _createTextVNode("   " + _toDisplayString(sandwich.wvtr.confidence), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (sandwich.heatSeal.min)
                ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                      _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "HS")
                    ], -1)),
                    _createElementVNode("div", _hoisted_51, [
                      _createElementVNode("div", _hoisted_52, [
                        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "VALUE:", -1)),
                        _createElementVNode("span", _hoisted_53, [
                          _createTextVNode(_toDisplayString(sandwich.heatSeal.min) + " ", 1),
                          (sandwich.heatSeal.max)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" - " + _toDisplayString(sandwich.heatSeal.max), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_54, "  " + _toDisplayString(sandwich.heatSeal.unit), 1)
                        ])
                      ]),
                      (sandwich.heatSeal.condition)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONDITION:", -1)),
                            _createElementVNode("span", _hoisted_56, _toDisplayString(sandwich.heatSeal.condition), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (sandwich.heatSeal.confidence)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONFIDENCE:", -1)),
                            _createElementVNode("span", _hoisted_58, [
                              _createVNode(AIConfidenceIcon, {
                                confidence: sandwich.heatSeal.confidence
                              }, null, 8, ["confidence"]),
                              _createTextVNode("   " + _toDisplayString(sandwich.heatSeal.confidence), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (sandwich.coatingTechnique.technique)
                ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                      _createElementVNode("p", {
                        class: "-rotate-90 font-medium text-black",
                        title: "Last Coating Technique"
                      }, "LCT")
                    ], -1)),
                    _createElementVNode("div", _hoisted_60, [
                      (sandwich.coatingTechnique.speed)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "SPEED:", -1)),
                            _createElementVNode("span", _hoisted_62, _toDisplayString(sandwich.coatingTechnique.speed), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (sandwich.coatingTechnique.meltingTemperature)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "TEMPERATURE:", -1)),
                            _createElementVNode("span", _hoisted_64, _toDisplayString(sandwich.coatingTechnique.meltingTemperature), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (sandwich.coatingTechnique.meltPressure)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                            _cache[18] || (_cache[18] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "PRESSSURE:", -1)),
                            _createElementVNode("span", _hoisted_66, _toDisplayString(sandwich.coatingTechnique.meltPressure), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (sandwich.coatingTechnique.technique)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                            _cache[19] || (_cache[19] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "TECHNIQUE:", -1)),
                            _createElementVNode("span", _hoisted_68, _toDisplayString(sandwich.coatingTechnique.technique), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])), [
        [_vShow, activeTab.value === index]
      ])
    }), 128))
  ]))
}
}

})