import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "new-asset-container" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

import ModalSlots from "@/components/ModalSlots.vue";
import TechnologyAssetsAddNewPatent from "@/components/TechnologyAssetsAddNewPatent.vue";
import TechnologyAssetsAddNewResearchPaper from '@/components/TechnologyAssetsAddNewResearchPaper.vue';
import TechnologyAssetsAddNewResearchProject from '@/components/TechnologyAssetsAddNewResearchProject.vue';
import TechnologyAssetsAddNewOtherTechnology from '@/components/TechnologyAssetsAddNewOtherTechnology.vue';
import { PlusIcon } from "@heroicons/vue/24/outline";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { computed, ref } from "vue";
import { ASSET_TYPES } from '@/models/technologyAssetConstants';
import { useStore } from "vuex";
import { useFormValidationNewAsset } from "@/composables/useFormValidationNewAsset";
import { NEW_ASSET_FIELDS } from "@/models/newAssetFormFields";
import { useRouter } from "vue-router";
import LoaderPage from "./LoaderPage.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsAddNew',
  setup(__props) {

const store = useStore();
const router = useRouter();
const isOpen = ref(false);
const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');
const createInProgress = ref(false);

const assetTypesList = [ASSET_TYPES.OTHER, ASSET_TYPES.PATENT, ASSET_TYPES.RESEARCH_PAPER, ASSET_TYPES.RESEARCH_PROJECT]
const assetType = ref(assetTypesList[0].graphqlValue)

const closeModal = () => { isOpen.value = false; formSubmitedType.value = null; }
const openModal = () => {
    if (!collapsed.value) {
        toggleSidebar()
    }
    isOpen.value = true; 
}
const assetTypeSelected = (index:number) =>  assetType.value = assetTypesList[index].graphqlValue;
const newAsset = computed(() => store.state.assetCreationModule.asset[assetType.value]);
const newUrl = computed(() => store.state.assetCreationModule.newAssetUrl);
const errorsDuringMutation = computed(() => store.state.assetCreationModule.errorsDuringMutation) 
const formSubmited = ref(false);
const formSubmitedType = ref();

const missingFields = computed(() => useFormValidationNewAsset(newAsset.value, assetType.value).missingFields.value);
const formIsValid = computed(() => useFormValidationNewAsset(newAsset.value, assetType.value).valid.value);

const showFilesUploadLoader = computed(() => store.getters['assetCreationModule/getShowFilesUploadLoader']);

const createAssetAndClose = async () => {
    formSubmited.value = true;
    formSubmitedType.value = assetType.value; 
    if ( formIsValid.value ) {
        const assetToCreate = assetTypesList.find(at => at.graphqlValue === assetType.value);
        const mutation = NEW_ASSET_FIELDS[assetToCreate.key].createMutation;
        createInProgress.value = true;
        await store.dispatch(mutation)
            .then(() => {
                formSubmited.value = false;
                formSubmitedType.value = null;
                createInProgress.value = false;
                store.commit('assetsModule/updateTabSelection', {selectedTab: assetToCreate});
                if (newUrl.value) {
                    router.push({ name: 'TechnologyAssetDetail', params: { id: newUrl.value }});
                }
            })
            .catch(() => {
                createInProgress.value = false;
            })
    }
}

const createAsssetAndAddMore = async () => {
    formSubmited.value = true;
    formSubmitedType.value = assetType.value; 
    if ( formIsValid.value ) {
        const assetToCreate = assetTypesList.find(at => at.graphqlValue === assetType.value);
        const mutation = NEW_ASSET_FIELDS[assetToCreate.key].createMutation;
        createInProgress.value = true;
        await store.dispatch(mutation)
            .then(() => {
                formSubmited.value = false;
                formSubmitedType.value = null;
                createInProgress.value = false;
                store.commit('assetsModule/updateTabSelection', {selectedTab: assetToCreate})
            })
            .catch(() => {
                createInProgress.value = false;
             } )
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["round-action-icon", {active: isOpen.value}])
    }, [
      _createVNode(_unref(PlusIcon), {
        class: "icon icon-small",
        onClick: openModal
      })
    ], 2),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      dialogClass: "create-asset-modal",
      onCloseModal: closeModal
    }, {
      header: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("p", null, null, -1)
      ])),
      body: _withCtx(() => [
        (showFilesUploadLoader.value)
          ? (_openBlock(), _createBlock(LoaderPage, {
              key: 0,
              "loader-text": "Creating Asset",
              "gif-position": "center"
            }))
          : _createCommentVNode("", true),
        _createVNode(_unref(TabGroup), {
          vertical: "",
          onChange: assetTypeSelected
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(TabList), { class: "vertical-tab-list" }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("p", { class: "form-subtitle" }, "Add New", -1)),
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(assetTypesList, (assetType) => {
                    return _createVNode(_unref(Tab), {
                      key: assetType.key
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(assetType.displayValue), 1)
                      ]),
                      _: 2
                    }, 1024)
                  }), 64))
                ]),
                _: 1
              }),
              _createVNode(_unref(TabPanels), { class: "forms-container" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(TabPanel), null, {
                    default: _withCtx(() => [
                      _createVNode(TechnologyAssetsAddNewOtherTechnology, {
                        "form-submited": formSubmited.value && formSubmitedType.value == assetType.value,
                        "form-is-valid": formIsValid.value,
                        "errors-fields": missingFields.value,
                        "errors-during-mutation": errorsDuringMutation.value['otherTechnology']
                      }, null, 8, ["form-submited", "form-is-valid", "errors-fields", "errors-during-mutation"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TabPanel), null, {
                    default: _withCtx(() => [
                      _createVNode(TechnologyAssetsAddNewPatent, {
                        "form-submited": formSubmited.value && formSubmitedType.value == assetType.value,
                        "form-is-valid": formIsValid.value,
                        "errors-fields": missingFields.value,
                        "errors-during-mutation": errorsDuringMutation.value['patent']
                      }, null, 8, ["form-submited", "form-is-valid", "errors-fields", "errors-during-mutation"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TabPanel), null, {
                    default: _withCtx(() => [
                      _createVNode(TechnologyAssetsAddNewResearchPaper, {
                        "form-submited": formSubmited.value && formSubmitedType.value == assetType.value,
                        "form-is-valid": formIsValid.value,
                        "errors-fields": missingFields.value
                      }, null, 8, ["form-submited", "form-is-valid", "errors-fields"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(TabPanel), null, {
                    default: _withCtx(() => [
                      _createVNode(TechnologyAssetsAddNewResearchProject, {
                        "form-submited": formSubmited.value && formSubmitedType.value == assetType.value,
                        "form-is-valid": formIsValid.value,
                        "errors-fields": missingFields.value
                      }, null, 8, ["form-submited", "form-is-valid", "errors-fields"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "button cancel",
          onClick: closeModal
        }, "cancel"),
        _createElementVNode("button", {
          class: _normalizeClass(["button green-accept", { 'cursor-not-allowed hover:shadow-none opacity-75': createInProgress.value }]),
          onClick: createAssetAndClose,
          disabled: createInProgress.value
        }, "Create", 10, _hoisted_2),
        _createElementVNode("button", {
          class: _normalizeClass(["button green-more", { 'cursor-not-allowed hover:shadow-none opacity-75': createInProgress.value }]),
          onClick: createAsssetAndAddMore,
          disabled: createInProgress.value
        }, "create and add more", 10, _hoisted_3)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})