import gql from 'graphql-tag';

export const ASSET_PROPS_FORM_VALUES = gql`
    query getPropsFormValues {
		unitTypes(orderBy: "abbreviation") {
			edges {
				node {
					id
					abbreviation
					name
					}
				}
      	}
		valueTypes {
			edges {
				node {
					id
					name
					symbol
					description
				}
			}
		}
		props (orderBy: "name") {
			edges {
				node {
					id
					name
					propCategory {
						id
						name
						propClass {
							id
							name
						}
					}
					baseValueType {
						id
						name
						description
						symbol
					}
					baseUnitType {
						id
						name
						abbreviation
					}
				}
			}
		}
	}`
